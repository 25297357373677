<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <widgets-carousel :list="banners"></widgets-carousel>
      </v-col>
    </v-row>
    <v-row v-if="fieldData(pageData, 'images1') != null">
      <v-col cols="12" md="6" lg="6" v-for="(image, index) in fieldData(pageData, 'images1').children" :key="index">
        <v-card flat>
          <a :href="image.children[0].jumpUrl">
            <v-img :src="image.children[0].url" class="white--text align-end">
            </v-img>
          </a>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="mt-16">
        <p class="text-center text-h4 text-md-h3 font-weight-bold">{{ fieldData(pageData, 'title').value }}</p>
      </v-col>
      <v-col cols="12">
        <p class="text-center text-md-h6 font-weight-medium">{{ fieldData(pageData, 'sub-title').value }}</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" lg="6" v-for="(image, index) in fieldData(pageData, 'images2').children" :key="index">
        <v-card flat>
          <a :href="image.children[0].jumpUrl" style="text-decoration: none;">
            <v-img :src="image.children[0].url">
              <v-card-title class="text-h4 font-weight-bold justify-center mt-2">{{ image.children[0].title }}</v-card-title>
              <p class="text-md-h6 font-weight-medium text-center justify-center mt-0">{{ image.children[0].value }}</p>
            </v-img>
          </a>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <p class="text-center text-h4 font-weight-bold mt-16">告诉我们您的业务需求</p>
        <p class="text-center text-h6 font-weight-medium">info@doublerise.com</p>
      </v-col>
    </v-row>
    <v-row class="mb-6">
      <v-col cols="0" md="0" lg="2"></v-col>
      <v-col cols="12" md="12" lg="8">
        <contact-demand type="BOTTOM_OF_HOMEPAGE"></contact-demand>
      </v-col>
      <v-col cols="0" md="0" lg="2"></v-col>
    </v-row>
  </v-container>
</template>
<script>
import store from "@/store";
import {
  mapGetters
} from "vuex";
import {
  FETCH_BANNER_LIST,
  FETCH_CMS_CONTENT,
} from "@/store/actions.type";
import {SET_ERROR} from "@/store/mutations.type";
export default {
  data() {
    return {
      pageData: {},
    }
  },
  beforeRouteEnter(to, from, next) {
    Promise.all([
      store.dispatch(FETCH_BANNER_LIST, ''),
    ]).then(() => {
      next();
    });
  },
  computed: {
    ...mapGetters(['banners']),
    fieldData() {
      return function(data, name) {
        for (const field of data.children) {
          if (field.uname === name) {
            return field;
          }
        }
        return null;
      };
    },
  },
  methods: {
    initPageData() {
      store.dispatch(FETCH_CMS_CONTENT, {name: 'home', version: this.$route.query.version || ''})
        .then((data) => {
          this.pageData = data.data.result.tree;
        })
        .catch((err) => {
          store.commit(SET_ERROR, {msg: err.response.data.message});
        });
    }
  },
  created() {
    this.initPageData();
  },
}
</script>

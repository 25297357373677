var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app-bar', {
    class: _vm.$vuetify.breakpoint.mobile ? '' : 'd-flex justify-center',
    attrs: {
      "app": "",
      "color": "white",
      "elevate-on-scroll": ""
    }
  }, [_c('v-app-bar-nav-icon', {
    staticClass: "hidden-md-and-up text-caption",
    attrs: {
      "ripple": false,
      "plain": ""
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.drawerToggle.apply(null, arguments);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("menu")])], 1), _c('v-toolbar-title', [_c('v-menu', {
    attrs: {
      "transition": "scroll-y-transition",
      "content-class": "elevation-0",
      "open-on-hover": "",
      "min-width": "100%",
      "max-height": "100%",
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "hidden-sm-and-down text-caption",
          staticStyle: {
            "text-transform": "none"
          },
          attrs: {
            "ripple": false,
            "large": "",
            "text": "",
            "plain": ""
          },
          on: {
            "click": function click($event) {
              return _vm.showExtBar('category');
            }
          }
        }, 'v-btn', attrs, false), on), [_vm._v("Apple 产品")])];
      }
    }])
  }, [_c('v-card', [_c('v-container', [_c('v-row', {
    staticClass: "d-flex align-center",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-btn', {
    staticClass: "text-h6 black--text",
    staticStyle: {
      "text-transform": "none"
    },
    attrs: {
      "to": {
        path: '/apple/product'
      },
      "ripple": false,
      "text": "",
      "plain": ""
    }
  }, [_vm._v("所有产品")])], 1)], 1), _vm._l(_vm.category, function (item, i) {
    return _c('v-row', {
      key: 'category_' + i,
      staticClass: "d-flex align-center",
      staticStyle: {
        "overflow-y": "auto"
      },
      attrs: {
        "no-gutters": ""
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "2"
      }
    }, [_c('v-btn', {
      staticClass: "text-h6 black--text",
      staticStyle: {
        "text-transform": "none"
      },
      attrs: {
        "to": {
          path: '/product/' + encodeURIComponent(item.categoryName.toLowerCase()),
          query: {
            brandId: item.brandId,
            firstCategoryId: item.id
          }
        },
        "ripple": false,
        "text": "",
        "plain": ""
      }
    }, [_vm._v(_vm._s(item.categoryName))])], 1), _c('v-col', {
      attrs: {
        "cols": "10"
      }
    }, [_c('v-breadcrumbs', {
      attrs: {
        "items": item.childrenList.map(function (children) {
          return {
            text: children.categoryName,
            disabled: false,
            to: {
              path: '/product/' + encodeURIComponent(item.categoryName.toLowerCase()) + '/' + encodeURIComponent(children.categoryName.toLowerCase()),
              query: {
                brandId: item.brandId,
                firstCategoryId: item.id,
                secondCategoryId: children.id
              },
              hash: '#categoryArea'
            }
          };
        }),
        "divider": "."
      },
      scopedSlots: _vm._u([{
        key: "item",
        fn: function fn(_ref2) {
          var item = _ref2.item;
          return [_c('v-breadcrumbs-item', {
            attrs: {
              "disabled": item.disabled,
              "to": item.to
            }
          }, [_c('span', {
            staticClass: "black--text text-caption"
          }, [_vm._v(_vm._s(item.text))])])];
        }
      }], null, true)
    })], 1)], 1);
  })], 2)], 1)], 1), _vm._l(_vm.navMenu, function (_ref3, i) {
    var link = _ref3.link,
      text = _ref3.text;
    return _c('v-btn', {
      key: i,
      staticClass: "hidden-sm-and-down text-caption",
      staticStyle: {
        "text-transform": "none"
      },
      attrs: {
        "to": link,
        "ripple": false,
        "large": "",
        "text": "",
        "plain": ""
      }
    }, [_vm._v(_vm._s(text))]);
  })], 2), _c('v-spacer', {
    staticClass: "hidden-md-and-up"
  }), _c('v-menu', {
    attrs: {
      "content-class": "elevation-0",
      "transition": "scroll-y-transition",
      "min-width": "100%",
      "max-height": "100%",
      "offset-y": "",
      "close-on-content-click": false
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref4) {
        var on = _ref4.on,
          attrs = _ref4.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": "",
            "plain": "",
            "small": "",
            "ripple": false
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("search")])], 1)];
      }
    }]),
    model: {
      value: _vm.searchMenu,
      callback: function callback($$v) {
        _vm.searchMenu = $$v;
      },
      expression: "searchMenu"
    }
  }, [_c('product-search-bar', {
    on: {
      "Close": function Close($event) {
        _vm.searchMenu = false;
      }
    }
  })], 1), _c('v-menu', {
    attrs: {
      "content-class": "elevation-0",
      "transition": "scroll-y-transition",
      "open-on-hover": "",
      "min-width": "100%",
      "max-height": "100%",
      "offset-y": "",
      "close-on-click": false,
      "close-on-content-click": false
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref5) {
        var on = _ref5.on,
          attrs = _ref5.attrs;
        return [_c('v-badge', _vm._g(_vm._b({
          attrs: {
            "value": _vm.cartCount,
            "overlap": "",
            "content": _vm.cartCount
          }
        }, 'v-badge', attrs, false), on), [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": "",
            "plain": "",
            "small": "",
            "ripple": false
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("work_outline")])], 1)], 1)];
      }
    }]),
    model: {
      value: _vm.cartMenu,
      callback: function callback($$v) {
        _vm.cartMenu = $$v;
      },
      expression: "cartMenu"
    }
  }, [_c('shopping-cart-card', {
    on: {
      "Close": function Close($event) {
        _vm.cartMenu = false;
      }
    }
  })], 1), !_vm.isAuthenticated ? _c('v-menu', {
    attrs: {
      "content-class": "elevation-0",
      "transition": "scroll-y-transition",
      "nudge-width": 200,
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref6) {
        var on = _ref6.on,
          attrs = _ref6.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "text-caption",
          attrs: {
            "text": "",
            "plain": "",
            "small": "",
            "ripple": false
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          attrs: {
            "left": "",
            "small": ""
          }
        }, [_vm._v("perm_identity")]), _vm._v("登录/注册 ")], 1)];
      }
    }], null, false, 405080032)
  }, [_c('v-card', {
    staticClass: "d-flex flex-column justify-start"
  }, [_c('v-card-text', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "text-h6"
  }, [_vm._v("你好")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_c('router-link', {
    attrs: {
      "to": {
        path: '/login'
      }
    }
  }, [_vm._v("登录")]), _vm._v("查看你的订单")], 1)]), _c('v-col', {
    staticClass: "my-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "text-h6"
  }, [_vm._v("个人中心")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "black--text pl-0",
    staticStyle: {
      "text-transform": "none"
    },
    attrs: {
      "to": {
        path: '/account'
      },
      "ripple": false,
      "text": "",
      "plain": ""
    }
  }, [_vm._v("注册")])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "black--text pl-0",
    staticStyle: {
      "text-transform": "none"
    },
    attrs: {
      "to": {
        path: '/login'
      },
      "ripple": false,
      "text": "",
      "plain": ""
    }
  }, [_vm._v("登录")])], 1)], 1)], 1)], 1)], 1) : _vm._e(), _vm.isAuthenticated ? _c('v-menu', {
    attrs: {
      "content-class": "elevation-0",
      "transition": "scroll-y-transition",
      "nudge-width": 200,
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref7) {
        var on = _ref7.on,
          attrs = _ref7.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "text-caption",
          attrs: {
            "text": "",
            "plain": "",
            "small": "",
            "ripple": false
          }
        }, 'v-btn', attrs, false), on), [_vm._v(" " + _vm._s(_vm.currentUser.username) + " "), _c('v-icon', [_vm._v("keyboard_arrow_down")])], 1)];
      }
    }], null, false, 3371430131)
  }, [_c('v-card', [_c('v-card-text', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('span', {
    staticClass: "text-h6"
  }, [_vm._v("个人中心")])]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-btn', {
    staticClass: "black--text pl-0",
    staticStyle: {
      "text-transform": "none"
    },
    attrs: {
      "to": {
        path: '/user-center'
      },
      "ripple": false,
      "text": "",
      "plain": "",
      "color": "primary"
    }
  }, [_vm._v("用户中心")])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-btn', {
    staticClass: "black--text pl-0",
    staticStyle: {
      "text-transform": "none"
    },
    attrs: {
      "ripple": false,
      "text": "",
      "plain": "",
      "color": "primary"
    },
    on: {
      "click": _vm.handleLogout
    }
  }, [_vm._v("退出登录")])], 1)], 1)], 1), _c('v-col', {
    staticClass: "my-4",
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('v-divider')], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('span', {
    staticClass: "text-h6"
  }, [_vm._v(_vm._s(_vm.currentUser.username) + " @ " + _vm._s(_vm.currentUser.enterpriseFullName) + ",你好")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "my-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "text-h7"
  }, [_vm._v("你的订单")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "black--text pl-0 compact-btn",
    staticStyle: {
      "text-transform": "none"
    },
    attrs: {
      "to": {
        path: '/order-list?type=intention'
      },
      "ripple": false,
      "text": "",
      "plain": ""
    }
  }, [_vm._v("意向单")])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "black--text pl-0 compact-btn",
    staticStyle: {
      "text-transform": "none"
    },
    attrs: {
      "to": {
        path: '/order-list?type=purchase_order'
      },
      "ripple": false,
      "text": "",
      "plain": ""
    }
  }, [_vm._v("采购单")])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "black--text pl-0 compact-btn",
    staticStyle: {
      "text-transform": "none"
    },
    attrs: {
      "to": {
        path: '/order-list?type=service_order'
      },
      "ripple": false,
      "text": "",
      "plain": ""
    }
  }, [_vm._v("服务工单")])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "black--text pl-0 compact-btn",
    staticStyle: {
      "text-transform": "none"
    },
    attrs: {
      "to": {
        path: '/order-list?type=repair_order'
      },
      "ripple": false,
      "text": "",
      "plain": ""
    }
  }, [_vm._v("维修工单")])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "my-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "text-h7"
  }, [_vm._v("账户设置")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "black--text pl-0 compact-btn",
    staticStyle: {
      "text-transform": "none"
    },
    attrs: {
      "to": {
        path: '/user-center?type=basic'
      },
      "ripple": false,
      "text": "",
      "plain": ""
    }
  }, [_vm._v("基础信息")])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "black--text pl-0 compact-btn",
    staticStyle: {
      "text-transform": "none"
    },
    attrs: {
      "to": {
        path: '/user-center?type=enterprise'
      },
      "ripple": false,
      "text": "",
      "plain": ""
    }
  }, [_vm._v("企业信息")])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "black--text pl-0 compact-btn",
    staticStyle: {
      "text-transform": "none"
    },
    attrs: {
      "to": {
        path: '/user-center?type=security'
      },
      "ripple": false,
      "text": "",
      "plain": ""
    }
  }, [_vm._v("安全设置")])], 1)], 1)], 1), _vm.serviceList && _vm.serviceList.length > 0 ? _c('v-col', {
    staticClass: "mb-4",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "my-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "text-h7"
  }, [_vm._v("服务申报")])]), _vm._l(_vm.serviceList, function (s, i) {
    return _c('v-col', {
      key: i,
      attrs: {
        "cols": "12"
      }
    }, [_c('v-btn', {
      staticClass: "black--text pl-0 compact-btn",
      staticStyle: {
        "text-transform": "none"
      },
      attrs: {
        "ripple": false,
        "text": "",
        "plain": ""
      },
      on: {
        "click": function click($event) {
          return _vm.openServiceDialog(s._id);
        }
      }
    }, [_vm._v(_vm._s(s.name))])], 1);
  })], 2)], 1) : _vm._e()], 1)], 1)], 1)], 1) : _vm._e(), _c('contact-service', {
    attrs: {
      "showDialog": _vm.showServiceDialog,
      "serviceId": _vm.serviceId
    },
    on: {
      "dialogCloseEvent": function dialogCloseEvent($event) {
        _vm.showServiceDialog = false;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
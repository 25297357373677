<!--服务申报-->
<template>
  <v-dialog 
    v-model="showDialog" 
    content-class="my-custom-dialog" 
    :width="$vuetify.breakpoint.mobile ? '' : '700'" 
    :fullscreen="$vuetify.breakpoint.mobile"
    @click:outside="handleClose"
  >
    <v-card flat>
      <v-card-title class="mb-8">
        <span class="text-center" style="margin: auto;">
          请告诉我们您的业务需求<br />
          400-815-8815 ｜ info@doublerise.com
        </span>
      </v-card-title>
      <v-card-text>
        <v-form v-model="validForm" @submit.prevent="submit">
          <v-row>
            <v-col cols="12" md="6" lg="6" class="my-0 py-0">
              <v-text-field
                v-model="editedItem.leaveMessageName"
                :rules="[rules.required]"
                type="text"
                label="姓名"
                hint="请输入您的姓名"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="6" class="my-0 py-0">
              <v-text-field
                v-model="editedItem.leaveMessagePhone"
                :rules="[rules.required, rules.phonenum]"
                type="text"
                label="电话"
                hint="请输入联系电话"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="6" class="my-0 py-0">
              <v-row>
                <v-col cols="12" class="mb-0 pb-0">
                  <v-text-field
                    v-model="editedItem.enterpriseName"
                    :rules="[rules.required]"
                    type="text"
                    label="公司"
                    hint="请输入您的公司"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="my-0 pt-0">
                  <v-text-field
                    v-model="editedItem.leaveMessageEmail"
                    :rules="[rules.required, rules.email]"
                    type="text"
                    label="邮箱"
                    hint="请输入电子邮箱"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6" lg="6" class="my-0 py-0">
              <v-textarea
                class="contactTextArea"
                v-model="editedItem.description"
                type="text"
                label="需求"
                rows="3"
                row-height="30px"
                outlined
                dense
                height="106"
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="12" class="mt-0 pt-0 text-center">
              <v-btn color="primary" @click="submit">提交</v-btn>
              <v-btn v-if="$vuetify.breakpoint.mobile" @click="handleClose" class="ml-4">取消</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { SET_ERROR } from "@/store/mutations.type";
import {
  PUBLISH_CONTACTUS
} from "@/store/actions.type";
import store from "@/store";
import { getClientType } from '@/common/operationlog'
import router from '@/router'

export default {
  props: ['showDialog', 'type', 'activity', 'plan'],
  data () {
    return {
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          if (value) {
            const pattern = /^1[3456789]\d{9}$/;
            return pattern.test(value) || '请输入正确的电话号码'
          } else {
            return true;
          }
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        email: value => {
          if (value) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || '错误的电子邮件地址'
          } else {
            return true;
          }
        },
      },
      validForm: true,
      editedItem: {
        leaveMessageName: '',
        leaveMessagePhone: '',
        enterpriseName: '',
        leaveMessageEmail: '',
        description: '',
        type: ''
      },
    }
  },
  methods: {
    handleClose() {
      this.$emit('dialogCloseEvent', false);
    },
    submit() {
      this.editedItem.type = this.type;
      this.editedItem.activityId = this.activity;
      this.editedItem.planId = this.plan;
      this.editedItem.channel = router.currentRoute.query?.channel || '';
      this.editedItem.client = getClientType();
      store.dispatch(PUBLISH_CONTACTUS, this.editedItem)
        .then((data) => {

          if (data.data.code !== 200) {
            store.commit(SET_ERROR, {msg: data.data.message});
            return;
          }

          this.editedItem = {};
          this.$store.commit(SET_ERROR, {msg: '留资成功', color: 'primary'});
          this.handleClose();
        })
        .catch((err) => {
          store.commit(SET_ERROR, {msg: err.response.data.message});
        });
    }
  },
  watch: {
    type(newVal, oldVal) {
      this.editedItem.type = newVal;
    }
  },
}
</script>
<style lang="scss" scoped>
.my-custom-dialog {
  bottom: 0 !important;
  right: 0 !important;
  position: absolute !important;
}
.aiFrame {
  width: 100%;
  height: 100%;
  border: 0px;
  margin:0;
}

.closePanel {
  margin: 0;
  padding: 0 !important;
  height: 36px;
  overflow: hidden;
  overflow-y: hidden !important;
}

.closeBtn {
  float: right;
  padding: 0;
  color: #000 !important;
}

.aiCard {
  height: 700px;
}

.contactTextArea {
  textarea {
    padding-bottom: 16px !important;
  }
}

@media screen and (max-width:700px){
  .aiCard {
    height: 100%;
  }
}
</style>

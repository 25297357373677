<template>
  <v-footer color="secondary" dark>
<!--    <v-spacer></v-spacer>-->
<!--    &copy; 2019-{{ new Date().getFullYear() }} 倍升互联（北京）科技有限公司-->
    <v-row no-gutters class="mt-6">
      <v-col cols="12" md="2" lg="2" class="mb-4">
        <v-img src="/static/logoword.svg" contain max-height="40" :max-width="$vuetify.breakpoint.mobile ? '100' : ''"></v-img>
      </v-col>
      <v-col cols="12" md="9" lg="9" class="d-flex justify-lg-space-between">
        <v-row no-gutters>
          <v-col cols="6" md="" lg="">
            <ul>
              <li class="caption font-weight-bold">Apple 产品</li>
              <li class="caption hidden-xs-only" v-for="(item, i) in category" :key="'category_'+ i">{{item.categoryName}}</li>
            </ul>
          </v-col>
          <v-col cols="6" md="" lg="">
            <ul>
              <li class="caption font-weight-bold">业务生态</li>
              <li class="caption hidden-xs-only">ESC 企业解决方案中心</li>
              <li class="caption hidden-xs-only">ESP 企业服务平台</li>
              <li class="caption hidden-xs-only">EC 企业员工优选商城</li>
            </ul>
          </v-col>
          <v-col cols="6" md="" lg="">
            <ul>
              <li class="caption font-weight-bold">设备租赁</li>
              <li class="caption hidden-xs-only">MDaaS 租赁服务</li>
              <li class="caption hidden-xs-only">客户案例介绍</li>
            </ul>
          </v-col>
          <v-col cols="6" md="" lg="">
            <ul>
              <li class="caption font-weight-bold">解决方案</li>
              <li class="caption hidden-xs-only">MDM 移动设备管理</li>
              <li class="caption hidden-xs-only">桌面虚拟化</li>
              <li class="caption hidden-xs-only">零信任安全</li>
            </ul>
          </v-col>
          <v-col cols="6" md="" lg="">
            <ul>
              <li class="caption font-weight-bold">售后和管理</li>
              <li class="caption hidden-xs-only">运营维护</li>
              <li class="caption hidden-xs-only">设备部署</li>
              <li class="caption hidden-xs-only">检测维修</li>
            </ul>
          </v-col>
          <v-col cols="6" md="" lg="">
            <ul>
              <li class="caption font-weight-bold">成功案例</li>
              <li class="caption hidden-xs-only">金融</li>
              <li class="caption hidden-xs-only">航空</li>
              <li class="caption hidden-xs-only">医药</li>
              <li class="caption hidden-xs-only">互联网</li>
            </ul>
          </v-col>
          <v-col cols="6" md="" lg="">
            <ul>
              <li class="caption font-weight-bold">活动和培训</li>
              <li class="caption hidden-xs-only">Apple 培训</li>
              <li class="caption hidden-xs-only">Jamf 培训</li>
              <li class="caption hidden-xs-only">市场活动</li>
            </ul>
          </v-col>
          <v-col cols="12" class="text-center">
            <a href="https://www.miit.gov.cn/" target="_blank" class="white--text caption">京ICP备13003150号-3</a>
            <p class="caption">&copy; 2023-{{ new Date().getFullYear() }} 倍升互联（北京）科技有限公司</p>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="0" md="1" lg="1"></v-col>
    </v-row>

  </v-footer>
</template>
<script>
import store from "@/store";
import {
  mapGetters
} from "vuex";
import {
  FETCH_CATEGORY_LIST
} from "@/store/actions.type";
export default {
  data() {
    return {
      categroyMapping: [
        {label: '业务生态', value: 'sales-support',},
        {label: '设备租赁', value: 'equipment-lease',},
        {label: '解决方案', value: 'solution',},
        {label: '售后和管理', value: 'after-sales',},
        {label: '成功案例', value: 'success-cases',},
        {label: '活动和培训', value: 'activity',},
      ],
    }
  },
  computed: {
    ...mapGetters(['category']),
  },
  created() {
    this.initialize()
  },
  methods: {
    initialize() {
      store.dispatch(FETCH_CATEGORY_LIST)
    }
  }
}
</script>
<style lang="scss" scoped>
ul li {
  list-style: none;
}
</style>

<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-img :src="fieldData(pageData, 'banner').url" height="100%" width="100%"></v-img>
      </v-col>
      <v-col cols="12" class="d-flex justify-center mt-4">
        <span class="text-h3 font-weight-bold" style="white-space: pre-wrap">{{ fieldData(pageData, 'title').value }}</span>
      </v-col>
      <v-col cols="12" class="d-flex justify-center">
        <p class="text-h5 text-center" style="white-space: pre-wrap">{{ fieldData(pageData, 'desc').value }}</p>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="text-center mb-12">
        <v-btn
          x-large
          depressed
          color="primary"
          class="rounded-xl large-btn"
          v-log="'ACTIVITY_TRAINING'"
          @click="showContactDialog('ACTIVITY_TRAINING')"
        >
          与我们取得联系
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" lg="12">
        <v-tabs
          centered grow show-arrows color="black" class="bordered-tabs"
        >
          <v-tab>Apple 培训</v-tab>
          <v-tab>Jamf 培训</v-tab>
          <v-tab>市场和活动</v-tab>

          {{ (tab1 = fieldData(pageData, 'tab').children[0], void 0) }}
          <v-tab-item :transition="fade-transition">
            <v-card flat>
              <v-card-text class="mt-4 mb-4">
                <v-row>
                  <v-col cols="12" class="d-flex justify-center mt-4">
                    <span class="text-h5 black--text font-weight-bold" style="white-space: pre-wrap">{{ fieldData(tab1, 'title').value }}</span>
                  </v-col>
                  <v-col cols="12" class="text-md-h5 my-4 grey--text text-center">
                    <p>
                      {{ fieldData(tab1, 'desc').value }}
                    </p>
                  </v-col>
                </v-row>
              </v-card-text>

              <div v-for="(activity, index) in activityFilter(fieldData(tab1, 'list').value)" :key="index">
                <v-card-text class="mt-4 mb-4">
                  <v-row>
                    <v-col cols="12" class="text-h5 text-md-h5 font-weight-bold my-4 text-center">
                      <span>{{ activity.name }}</span>
                    </v-col>
                  </v-row>
                  <v-divider class="my-4"></v-divider>
                  <v-row>
                    <v-col cols="12" md="6" lg="6">
                      <v-img :src="activity.imgUrl"></v-img>
                    </v-col>
                    <v-col cols="12" md="6" lg="6">
                      <v-row>
                        <v-col cols="12" md="12" lg="12">
                          <span class="text-h6">课程内容</span>
                        </v-col>
                        <v-col cols="12" md="12" lg="12">
                          <p class="text-body-1 grey--text">{{ activity.description }}</p>
                        </v-col>
                        <v-col cols="12" md="12" lg="12">
                          <span class="text-h6">考试认证</span>
                        </v-col>
                        <v-col cols="12" md="12" lg="12">
                          <p class="text-body-1 grey--text my-2">{{ activity.certificate }}</p>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-text>
                  <v-list
                    two-line
                  >
                    <div v-for="(plan, pi) in activity.plans" :key="pi">
                      <v-divider v-if="pi !== 0"></v-divider>

                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title class="text-h6">{{ dateFormat(plan.startDate, 'YYYY年MM月DD日') }} - {{ dateFormat(plan.endDate, 'MM月DD日') }}</v-list-item-title>
                          <v-list-item-subtitle class="text-body-1 grey-text">{{ plan.address }} ｜ {{ timeFormat(plan.startTime, 'HH:mm') }} - {{ timeFormat(plan.endTime, 'HH:mm') }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn color="primary" x-large class="page-h5" @click="showContactDialog('ACTIVITY_TRAINING', activity.id, plan.id)">报名</v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </div>
                  </v-list>
                </v-card-text>
              </div>
            </v-card>
          </v-tab-item>

          {{ (tab2 = fieldData(pageData, 'tab').children[1], void 0) }}
          <v-tab-item :transition="fade-transition">
            <v-card flat>
              <v-card-text class="mt-4 mb-4">
                <v-row>
                  <v-col cols="12" class="d-flex justify-center mt-4">
                    <span class="text-h5 black--text font-weight-bold" style="white-space: pre-wrap">{{ fieldData(tab2, 'title').value }}</span>
                  </v-col>
                  <v-col cols="12" class="text-md-h5 my-4 justify-center grey--text text-center">
                    <p>
                      {{ fieldData(tab2, 'sub-title').value }}
                    </p>
                  </v-col>
                  <v-col cols="12" class="text-md-h5 my-4 grey--text">
                    <p>
                      {{ fieldData(tab2, 'desc').value }}
                    </p>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-img :src="fieldData(tab2, 'jamf-banner').url"></v-img>

              <div class="page-content my-4" v-html="fieldData(tab2, 'content').value"></div>

              <v-divider></v-divider>

              {{ (actjamf = activityFilter(fieldData(tab2, 'activity').value), void 0) }}
              <div v-if="actjamf != null && actjamf.length > 0">
                <v-card-text class="mt-4 mb-4">
                  {{ (activity = actjamf[0], void 0) }}
                  <v-row>
                    <v-col cols="12" md="6" lg="6">
                      <v-img :src="activity.imgUrl"></v-img>
                    </v-col>
                    <v-col cols="12" md="6" lg="6">
                      <v-row>
                        <v-col cols="12" md="12" lg="12" class="text-h6">
                          <span class="page-h5">课程内容</span>
                        </v-col>
                        <v-col cols="12" md="12" lg="12">
                          <p class="page-content">{{ activity.description }}</p>
                        </v-col>
                        <v-col cols="12" md="12" lg="12" class="text-h6">
                          <span class="page-h5">考试认证</span>
                        </v-col>
                        <v-col cols="12" md="12" lg="12">
                          <p class="page-content">{{ activity.certificate }}</p>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-text>
                  <v-list
                    two-line
                  >
                    <div v-for="(plan, pi) in activity.plans" :key="pi">
                      <v-divider v-if="pi !== 0"></v-divider>

                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title class="text-h6">{{ dateFormat(plan.startDate, 'YYYY年MM月DD日') }} - {{ dateFormat(plan.endDate, 'MM月DD日') }}</v-list-item-title>
                          <v-list-item-subtitle class="text-body-1 grey--text">{{ plan.address }} ｜ {{ timeFormat(plan.startTime, 'HH:mm') }} - {{ timeFormat(plan.endTime, 'HH:mm') }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn color="primary" x-large class="page-h5" @click="showContactDialog('ACTIVITY_TRAINING', activity.id, plan.id)">报名</v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </div>
                  </v-list>
                </v-card-text>
              </div>
            </v-card>
          </v-tab-item>

          {{ (tab3 = fieldData(pageData, 'tab').children[2], void 0) }}
          <v-tab-item :transition="fade-transition">
            <v-card flat>
              <v-card-text class="mt-4 mb-4">
                <v-row>
                  <v-col cols="12" class="d-flex justify-center mt-4">
                    <span class="text-h5 black--text font-weight-bold" style="white-space: pre-wrap">{{ fieldData(tab3, 'title').value }}</span>
                  </v-col>
                  <v-col cols="12" class="text-md-h5 my-4 justify-center grey--text text-center">
                    <p>
                      {{ fieldData(tab3, 'sub-title').value }}
                    </p>
                  </v-col>
                </v-row>
              </v-card-text>

              {{ (market = activityFilter(fieldData(tab3, 'activity').value), void 0) }}
              <div v-if="market != null && market.length > 0" v-for="(activity, index) in market" :key="index">
                <v-card-title class="mt-4 text-h5">
                  <span class="activity-title">{{ activity.name }}</span>
                </v-card-title>
                <v-divider></v-divider>

                <v-img :src="activity.imgUrl"></v-img>

                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="2" lg="2">
                      <span class="text-h6">活动内容</span>
                    </v-col>
                    <v-col cols="12" md="10" lg="10">
                      <p class="text-body-1 grey--text">{{ activity.description }}</p>
                    </v-col>

                    <v-col cols="12" md="2" lg="2">
                      <span class="text-h6">参与对象</span>
                    </v-col>
                    <v-col cols="12" md="10" lg="10">
                      <p class="text-body-1 grey--text">{{ activity.target }}</p>
                    </v-col>

                    {{ (plan = activity.plans[0], void 0) }}

                    <v-col cols="12" md="2" lg="2">
                      <b class="text-h6">活动时间</b>
                    </v-col>
                    <v-col cols="12" md="10" lg="10">
                      <p class="text-body-1 grey--text">{{ dateFormat(plan.startDate, 'YYYY年MM月DD日') }} ｜ {{ timeFormat(plan.startTime, 'HH:mm') }} - {{ timeFormat(plan.endTime, 'HH:mm') }} {{ timeFormat(plan.signIn, 'HH:mm') }} 签到注册</p>
                    </v-col>

                    <v-col cols="12" md="2" lg="2">
                      <b class="text-h6">活动地址</b>
                    </v-col>
                    <v-col cols="12" md="10" lg="10">
                      <p class="text-body-1 grey--text">{{ plan.address }}</p>
                    </v-col>

                    <v-col cols="12" md="12" lg="12" class="text-center">
                      <v-btn color="primary" x-large class="text-h6" @click="showContactDialog('ACTIVITY_TRAINING', activity.id, plan.id)">报名</v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </div>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
     :showDialog="contactDialog" :type="submitType" :activity="activityId" :plan="planId" @dialogCloseEvent="contactDialog = false"></contact-dialog>
  </v-container>
</template>

<style lang="scss" scoped>
.container p {
  white-space: pre-line;
}

.page-content {
  font-weight: 300;
  font-size: 1rem;
  letter-spacing: 0.0125em;
  line-height: 2;

  p {
    color: #9e9e9e !important;
    caret-color: #9e9e9e !important;
  }
}

.page-btn {
  font-weight: 400;
  font-size: 24px;
}

.page-list {
  li {
    list-style: none;
  }
}

.activity-title {
  margin-left: auto;
  margin-right: auto;
}
.large-btn {
  width: 300px;
  font-size: 24px;
}
.tab-title {
  font-weight: 500;
  font-size: 24px;
  padding: 10px 40px;
  border-bottom: 1px solid #d9d9d9;
}

.tab-mobile-title {
  padding: 10px 10px;
  border-bottom: 1px solid #d9d9d9;
}

@media screen and (max-width:700px){
  .text-pretty {
    word-break: keep-all;
    word-wrap: break-word;
  }
}
</style>

<script>
import { SET_ERROR } from "@/store/mutations.type";
import {
  FETCH_CMS_CONTENT,
  FETCH_ACTIVITY_LIST,
} from "@/store/actions.type";
import store from "@/store";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export default {
  data() {
    return {
      submitType: 'ACTIVITY_TRAINING',
      activityId: null,
      planId: null,
      contactDialog: false,
      pageData: {},
      activityList: [],
    }
  },
  methods: {
    showContactDialog(type, aid, pid) {
      this.submitType = type;
      this.contactDialog = true;
      this.activityId = aid;
      this.planId = pid;
    },

    initPageData() {
      store.dispatch(FETCH_CMS_CONTENT, {name: 'activity', version: this.$route.query.version || ''})
        .then((data) => {
          this.pageData = data.data.result.tree;
        })
        .catch((err) => {
          store.commit(SET_ERROR, {msg: err.response.data.message});
        });

      store.dispatch(FETCH_ACTIVITY_LIST)
        .then((data) => {
          this.activityList = data.data.result;
        })
        .catch((err) => {
          store.commit(SET_ERROR, {msg: err.response.data.message});
        });
    }
  },
  created() {
    this.initPageData();
  },
  computed: {
    fieldData() {
      return function(data, name) {
        for (const field of data.children) {
          if (field.uname === name) {
            return field;
          }
        }
        return null;
      };
    },
    activityFilter() {
      return function(category) {
        return this.activityList.filter(a => a.category === category);
      };
    },
    dateFormat() {
      return function(dateString, format) {
        return dayjs(dateString).format(format);
      };
    },
    timeFormat() {
      return function(timeString, format) {
        return dayjs(timeString, 'HH:mm:ss').format(format);
      };
    },
  },
}
</script>

<template>
  <v-app-bar
    bottom
    fixed
    height="160px"
  >
    <v-container>
      <v-row class="justify-center">
        <v-col v-if="!$vuetify.breakpoint.mobile" cols="0" md="7" lg="7" class="subtitle-2">{{skuInfo.skuName}}</v-col>
        <v-col cols="12" md="5" lg="5" class="d-flex justify-end">
          <p class="text-h5 font-weight-bold text-right">RMB {{skuInfo.leasePrice}}/月 或 <br>
            RMB {{skuInfo.salesPrice}} <br>
            <v-btn 
              depressed text x-small 
              :ripple="false" color="primary" 
              v-log="'PRODUCT_DETAIL_LEASE_PRICE'"
              :log-param="spuName"
              @click="showContactDialog('PRODUCT_DETAIL_LEASE_PRICE')">
              点击了解更多详情
            </v-btn>
            <v-btn 
              depressed text x-small 
              :ripple="false" color="primary" 
              v-log="'PRODUCT_DETAIL_ENTERPRISE_PRICE'"
              :log-param="spuName"
              @click="showContactDialog('PRODUCT_DETAIL_ENTERPRISE_PRICE')">
              点击咨询企业优惠价格<v-icon small color="primary" right>help</v-icon>
            </v-btn>
          </p>
          <v-btn depressed rounded color="primary" class="mx-2" @click="addCart()">增加到购物袋</v-btn></v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="dialogPay" width="500" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-card-title class="text-h5">
          请选择支付方式
          <v-spacer></v-spacer>
          <v-btn icon @click="closePay()"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12" lg="12" class="subtitle-2 mb-n4">支付方式</v-col>
            <v-col cols="12" md="12" lg="12">
              <v-btn
                :color="operate['purchaseType'] === 1 ? 'primary' : 'grey darken-3'"
                @click="setPay('sale')"
                depressed outlined block height="75" class="rounded-lg mb-2 justify-start subtitle-1"
              >一次性付款</v-btn>
              <v-btn
                :color="operate['purchaseType'] === 0 ? 'primary' : 'grey darken-3'"
                @click="setPay('rental')"
                depressed outlined block height="75" class="rounded-lg mb-2 justify-start subtitle-1"
              >分期付款</v-btn>
            </v-col>
          </v-row>
          <v-card color="grey lighten-4" flat v-if="rentalOptions">
            <v-card-title>租赁方式</v-card-title>
            <v-card-text>
              <p class="subtitle-2">您期望的租期</p>
              <v-select
                v-model="operate.leasePayMethod"
                :items="leaseMethod"
                item-text="name"
                item-value="code"
                clearable
              ></v-select>
              <p class="subtitle-2">您的付款方式</p>
              <v-select
                v-model="operate.leasePeriod"
                :items="leasePeriod"
                item-text="name"
                item-value="code"
                clearable
              ></v-select>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :block="$vuetify.breakpoint.mobile" depressed rounded color="primary" @click="submit()">确认</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <contact-dialog :showDialog="contactDialog" :type="contactType" @dialogCloseEvent="contactDialog = false"></contact-dialog>
  </v-app-bar>
</template>
<script>
import Util from "@/common/util";
import {mapGetters} from "vuex";
import store from "@/store";
import {
  ADD_PROD_CART, FETCH_CART_LIST, CHECK_AUTH
} from "@/store/actions.type";

export default {
  props: {
    skuInfo: {
      type: Object,
      required: true,
    },
    spuId: {
      type: Number,
      required: true,
    },
    spuName: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      dialogPay: false,
      operate: {
        "count": 1,
        "operateType": "ADD",
        "purchaseType": 1,
        "skuId": this.skuInfo.id,
        "spuId": this.spuId,
        "leasePayMethod": '月付',
        "leasePeriod": 12
      },
      defaultOperate: {
        "count": 1,
        "operateType": "ADD",
        "purchaseType": 1,
        "skuId": null,
        "spuId": null,
        "leasePayMethod": '月付',
        "leasePeriod": 12
      },
      rentalOptions: false,
      contactType: '',
      contactDialog: false,
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'cart_list']),
    leaseMethod () {
      return Util.categories('leaseMethod')
    },
    leasePeriod () {
      return Util.categories('leasePeriod')
    },
  },
  watch: {
    dialogPay (val) {
      val || this.closePay()
    },
  },
  methods: {
    showContactDialog(type) {
      this.contactType = type;
      this.contactDialog = true;
    },
    addCart() {
      store.dispatch(CHECK_AUTH)
        .then(() => {
          if (this.isAuthenticated) {
            this.dialogPay = true
          } else {
            this.$router.push({ path: '/login?redirect='+ this.fullPath() })
          }
        })
        .catch((err) => {
          this.$router.push({ path: '/login?redirect='+ this.fullPath() })
        });
    },
    closePay() {
      this.rentalOptions = false
      this.dialogPay = false
      this.$nextTick(() => {
        this.operate = Object.assign({}, this.defaultOperate)
      })
    },
    setPay(type) {
      if (type === 'sale') {
        this.operate['purchaseType'] = 1
        this.rentalOptions = false
      } else {
        this.operate['purchaseType'] = 0
        this.rentalOptions = true
      }
    },
    submit() {
      this.operate.skuId = this.skuInfo.id
      this.operate.spuId = this.spuId
      store.dispatch(ADD_PROD_CART, this.operate)
        .then(() => {
          store.dispatch(FETCH_CART_LIST, '')
          this.closePay()
        })
    },
    fullPath() {
      const queryString = Object.keys(this.$route.query)
        .map(key => `${key}=${this.$route.query[key]}`)
        .join('&');
      return `${this.$route.path}${queryString ? `?${queryString}` : ''}`;
    }
  }
}
</script>

<template>
  <v-container>
    <v-breadcrumbs :items="pageBread" class="mb-8">
      <template v-slot:divider>
        <v-icon>chevron_right</v-icon>
      </template>
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
          :disabled="item.disabled"
          :to="item.to"
          :link="true"
          :exact="true"
        >
          <span :class="item.disabled ? 'grey--text' : 'black--text'">{{ item.text }}</span>
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <v-row>
      <v-col cols="12" md="6" lg="6" class="px-4">
        <v-carousel
          v-model="bannersList"
          delimiter-icon="remove"
          height="320px"
        >
          <v-carousel-item
            v-for="(item,i) in banners"
            :key="'carousel_'+ i"
            :src="item"
            reverse-transition="fade-transition"
            transition="fade-transition"
          ></v-carousel-item>
        </v-carousel>

        <v-slide-group
          v-model="bannersThumbnail"
          class="pa-4"
          show-arrows
        >
          <v-slide-item
            v-for="(n, j) in banners"
            :key="'thumbnail_'+ n"
            v-slot="{ active, toggle }"
          >
            <v-img :src="n" contain max-height="100px" max-width="100px" class="mx-2 v-list-item--link" @click="setBanners(j)"></v-img>
          </v-slide-item>
        </v-slide-group>
      </v-col>
      <v-col cols="12" md="6" lg="6">
        <p class="text-h4 font-weight-bold">{{product_info.productName}}</p>
        <P class="subtitle-2" v-for="(attr, index) in attrSummary" :key="'summary_text_'+ index">{{ attr }}</P>
        <v-divider class="my-4"></v-divider>
        <v-row v-for="(attr, index) in product_info.attributesList" :key="'attr_'+ index">
          <v-col cols="12"  v-if="attr.name === '颜色'">
            <v-row>
              <v-col cols="12" md="12" lg="12" class="subtitle-2 mb-n4">{{attr.name}}</v-col>
              <v-col cols="12" md="12" lg="12">
                <v-btn
                  v-for="(v, j) in attr.attributesValueList"
                  :key="'attr_value_'+'_'+ index +'_'+ j"
                  :color="attributes[v.nameId] === v.valueId ? 'primary' : 'white'"
                  @click="setSelectValue(v)"
                  depressed outlined fab small
                >
                  <v-icon x-large v-if="v.valueInfo === '深空黑色'" color="#727375">circle</v-icon>
                  <v-icon x-large v-if="v.valueInfo === '深空灰色'" color="#727375">circle</v-icon>
                  <v-icon x-large v-if="v.valueInfo === '银色'" color="#dfe0e2">circle</v-icon>
                  <v-icon x-large v-if="v.valueInfo === '黑色钛金属'" color="#3f4144">circle</v-icon>
                  <v-icon x-large v-if="v.valueInfo === '白色钛金属'" color="#f1efe8">circle</v-icon>
                  <v-icon x-large v-if="v.valueInfo === '原色钛金属'" color="#aca698">circle</v-icon>
                  <v-icon x-large v-if="v.valueInfo === '蓝色钛金属'" color="#3e4250">circle</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" v-else>
            <v-row>
              <v-col cols="12" md="12" lg="12" class="subtitle-2 mb-n4">{{attr.name}}</v-col>
              <v-col cols="12" md="12" lg="12">
                <v-btn
                  v-for="(v, j) in attr.attributesValueList"
                  :key="'attr_value_'+'_'+ index +'_'+ j"
                  :color="attributes[v.nameId] === v.valueId ? 'primary' : 'grey darken-3'"
                  @click="setSelectValue(v)"
                  depressed outlined block height="75" class="rounded-lg mb-2 justify-start subtitle-1"
                >{{v.valueInfo}}</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col ><pre style="white-space: pre-wrap" v-html="product_info.picTextDescription"></pre></v-col>
    </v-row>
    <product-price-bar v-if="product_info.id" :skuInfo="currentSKU" :spuId="product_info.id" :spuName="product_info.productName"></product-price-bar>
  </v-container>
</template>
<script>
import Util from "@/common/util";
import {mapGetters} from "vuex";
import {
  FETCH_PROD
} from "@/store/actions.type";
import store from "@/store";
import {SET_ERROR} from "@/store/mutations.type";
export default {
  data() {
    return {
      pageBread: [],
      banners: [],
      bannersList: 0,
      bannersThumbnail: null,
      attrSummary: null,
      attributes: {},
      currentSKU: {}
    }
  },
  computed: {
    ...mapGetters(['category', 'product_info']),
  },
  created() {
    this.getData();
  },
  methods: {
    //获取数据列表
    getData() {
      store.dispatch(FETCH_PROD, this.$route.query?.id || 0)
        .then(({ result }) => {
          // 生成面包屑导航
          const objFirstCategory = this.category.find(item => item.id === parseInt(this.$route.query.firstCategoryId))
          const objSecondCategory = objFirstCategory.childrenList.find(item => item.id === parseInt(this.$route.query.secondCategoryId))
          const toFirstCategory = { path: '/product/'+ encodeURIComponent(objFirstCategory.categoryName.toLowerCase()), query: { brandId: objFirstCategory.brandId, firstCategoryId: objFirstCategory.id } }
          const toSecondCategory = { path: '/product/'+ encodeURIComponent(objFirstCategory.categoryName.toLowerCase()) + '/' + encodeURIComponent(objSecondCategory.categoryName.toLowerCase()), query: { brandId: objSecondCategory.brandId, firstCategoryId: objFirstCategory.id, secondCategoryId: objSecondCategory.id }, hash: '#categoryArea' }

          this.pageBread = [
            { text: result.brand, disabled: true, to: '' },
            { text: result.firstCategory, disabled: false, to: toFirstCategory },
            { text: result.secondCategory, disabled: false, to: toSecondCategory },
            { text: result.productName, disabled: true, to: '' }
          ]
          // 默认显示第一个SKU商品
          const defaultProduct = result.skuList[0]
          this.attributes['purchaseType'] = 'sale'
          this.setDefaultProduct(defaultProduct)
          this.$forceUpdate()
        })
    },
    setBanners(index) {
      this.bannersList = index
    },
    setSelectValue(attr) {
      let findSku = false;
      const selectAttr = Object.assign({}, this.attributes);
      selectAttr[attr.nameId] = attr.valueId;

      // 匹配一致的SKU商品
      let currAttributes = []
      for (const property in selectAttr) {
        if (property !== 'purchaseType' && property !== 'undefined') {
          currAttributes.push({
            attrNameId: parseInt(property),
            attrValueId: parseInt(selectAttr[property])
          })
        }
      }

      for (const attr of this.product_info.skuList) {
        if (Util.arraysEqual(attr.attributesInfoList, currAttributes)) {
          this.attributes[attr.nameId] = attr.valueId
          this.setDefaultProduct(attr)
          findSku = true
        }
      }
      if (!findSku) store.commit(SET_ERROR, {msg: '没有此配置对应的产品，请重新选择。', color: 'primary'});

      this.$forceUpdate()
    },
    setDefaultProduct(prod) {
      //设置默认显示商品
      this.currentSKU = prod
      this.attrSummary = prod?.attrValueList
      this.banners = prod?.picUrlList
      for (const attr of prod.attributesInfoList) {
        this.attributes[attr.attrNameId] = attr.attrValueId
      }
      this.$forceUpdate()
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .v-carousel__controls {
  background: none;

  .v-carousel__controls__item {
    color: #000;
    opacity: 0.62;
  }
  .v-item--active {
    opacity: 1;
  }
}
</style>
<!--采购单详情-->
<template>
  <v-dialog 
    v-model="showDialog" 
    content-class="my-custom-dialog" 
    :fullscreen="true"
    @click:outside="handleClose"
  >
    <v-card flat>
      <v-toolbar>
        <v-toolbar-title>
          {{ detail.orderNumber }}
          <v-chip v-if="detail.status == 0" label text-color="white" color="#000">待执⾏</v-chip>
          <v-chip v-if="detail.status == 1" label text-color="white" color="#1890FF">处理中</v-chip>
          <v-chip v-if="detail.status == 2" label text-color="white" color="#ccc">已取消</v-chip>
          <v-chip v-if="detail.status == 3" label text-color="white" color="#f50">已拒绝</v-chip>
          <v-chip v-if="detail.status == 4" label text-color="white" color="#87d068">已完成</v-chip>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            icon
            @click="handleClose"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="mt-12">
        <v-expansion-panels accordion v-model="panels" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header>基本信息</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="4" md="2">
                  PO号
                </v-col>
                <v-col cols="8" md="4">
                  {{ detail.poNumber }}
                </v-col>
                <v-col cols="4" md="2">
                  DT号
                </v-col>
                <v-col cols="8" md="4">
                  {{ detail.outNumber }}
                </v-col>
                <v-col cols="4" md="2">
                  订单编号
                </v-col>
                <v-col cols="8" md="4">
                  {{ detail.orderNumber }}
                </v-col>
                <v-col cols="4" md="2">
                  客户
                </v-col>
                <v-col cols="8" md="4">
                  {{ detail.enterprise?.name }}
                </v-col>
                <v-col cols="4" md="2">
                  创建人
                </v-col>
                <v-col cols="8" md="4">
                  {{ detail.creator?.personal?.name }}
                </v-col>
                <v-col cols="4" md="2">
                  创建时间
                </v-col>
                <v-col cols="8" md="4">
                  {{ detail.createTime }}
                </v-col>
                <v-col cols="4" md="2">
                  发票信息
                </v-col>
                <v-col cols="8" md="4">
                  {{ invoiceType }}
                </v-col>
                <v-col cols="4" md="2">
                  收货信息
                </v-col>
                <v-col cols="8" md="4">
                  {{ detail.deliveryInfo?.address }} <br />
                  {{ detail.deliveryInfo?.phone }} <br />
                  {{ detail.deliveryInfo?.name }}
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>详细信息</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table
                :headers="detailHeaders"
                :items="detail.productList"
                :items-per-page="100"
                hide-default-footer
                class="orderTable"
                no-data-text="暂无数据"
              >
              </v-data-table>
              <div class="ml-4 mt-4" style="color: red;">数量合计<span class="ml-6">{{ productCount }}</span></div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>相关工单</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table
                :headers="caseHeaders"
                :items="detail.caseList"
                :items-per-page="100"
                hide-default-footer
                class="orderTable"
                no-data-text="暂无数据"
              >
              <template v-slot:item.status="{ item }">
                <span v-if="item.status == 0" style="color: #000;">待执⾏</span>
                <span v-if="item.status == 1" style="color: #1890FF;">处理中</span>
                <span v-if="item.status == 2" style="color: #ccc;">已取消</span>
                <span v-if="item.status == 3" style="color: #f50;">已拒绝</span>
                <span v-if="item.status == 4" style="color: #87d068;">已完成</span>
              </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { SET_ERROR } from "@/store/mutations.type";
import {
  FETCH_ORDER_DETAIL
} from "@/store/actions.type";
import store from "@/store";
import { watch } from 'vue';

export default {
  props: ['showDialog', 'orderId'],
  data () {
    return {
      detailHeaders: [
        {
          text: '产品型号',
          align: 'start',
          value: 'partNumber',
        },
        { text: '产品名称', value: 'productName' },
        { text: '总数量', value: 'count.order' },
        { text: '已开单数量', value: 'count.cases' },
        { text: '品牌', value: 'productVendor.name' },
        { text: '产品类型', value: 'productType.name' },
      ],
      caseHeaders: [
        {
          text: '工单编号',
          align: 'start',
          value: 'caseNumber',
        },
        { text: '当前任务', value: 'currentTaskId.name' },
        { text: '开单设备数量', value: 'productCount' },
        { text: '已出库数量', value: 'productCount' },
        { text: '状态', value: 'status' },
        { text: '更新时间', value: 'updateTime' },
        { text: '创建时间', value: 'createTime' },
      ],
      detail: {},
      panels: [0],
    }
  },
  methods: {
    handleClose() {
      this.$emit('dialogCloseEvent', false);
    },
    loadOrderDetail(id) {
      if (id === null) {
        return;
      }
      console.log(id);

      store.dispatch(FETCH_ORDER_DETAIL, id)
        .then((data) => {
          if (data.data.code !== 200) {
            store.commit(SET_ERROR, {msg: data.data.message});
            return;
          }
          
          this.detail = data.data.result;
        })
        .catch((err) => {
          store.commit(SET_ERROR, {msg: err.response.data.message});
        });
    },
  },
  watch: {
    orderId(newVal, oldVal) {
        this.loadOrderDetail(newVal);
    }
  },
  computed: {
    invoiceType() {
      if (this.detail.invoiceInfo == null || this.detail.invoiceInfo.invoiceType == null) {
        return "";
      }
      if (this.detail.invoiceInfo.invoiceType === "0") {
        return "增值税普通发票";
      } else if (this.detail.invoiceInfo.invoiceType === "1") {
        return "增值税专用发票";
      }
      return "";
    },
    productCount() {
      let sum = 0;
      if (!this.detail.productList) {
        return sum;
      }
      for (let i=0; i<this.detail.productList.length; i++) {
        sum += parseInt(this.detail.productList[i].count.order);
      }
      return sum;
    },
  }
}
</script>
<style scoped>
.my-custom-dialog {
  bottom: 0 !important;
  right: 0 !important;
  position: absolute !important;
}
.aiFrame {
  width: 100%;
  height: 100%;
  border: 0px;
  margin:0;
}

.closePanel {
  margin: 0;
  padding: 0 !important;
  height: 36px;
  overflow: hidden;
  overflow-y: hidden !important;
}

.closeBtn {
  float: right;
  padding: 0;
  color: #000 !important;
}

.aiCard {
  height: 700px;
}

.filePanel {
  width: 100%;
  border-color: rgba(0, 0, 0, .42);
  border-style: solid;
  border-width: thin;
  border-radius: 4px;
  padding-top: 2px;
  padding-bottom: 2px;

  label {
    color: rgba(0, 0, 0, 0.6);
    padding: 6px 0 6px 10px;
  }

  button {
    margin-right: 10px;
    margin-top: 3px;
  }
}

@media screen and (max-width:700px){
  .aiCard {
    height: 100%;
  }
}
</style>

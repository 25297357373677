var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-carousel', {
    staticClass: "carouselCtrl",
    attrs: {
      "delimiter-icon": "remove",
      "reverse-transition": "fade-transition",
      "transition": "fade-transition",
      "show-arrows": !_vm.$vuetify.breakpoint.mobile,
      "height": _vm.$vuetify.breakpoint.mobile ? '215px' : '575px'
    },
    model: {
      value: _vm.model,
      callback: function callback($$v) {
        _vm.model = $$v;
      },
      expression: "model"
    }
  }, _vm._l(_vm.list, function (item, i) {
    return _c('v-carousel-item', {
      key: 'banners_' + i
    }, [_c('v-img', {
      staticStyle: {
        "cursor": "pointer",
        "height": "100%"
      },
      attrs: {
        "src": item.url
      },
      on: {
        "click": function click($event) {
          return _vm.handleClick(item);
        }
      }
    })], 1);
  }), 1), _c('contact-dialog', {
    attrs: {
      "showDialog": _vm.contactDialog,
      "type": "VISION_PRO_BOOKING"
    },
    on: {
      "dialogCloseEvent": function dialogCloseEvent($event) {
        _vm.contactDialog = false;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-card flat>
    <v-carousel
      v-model="model"
      delimiter-icon="remove"
      reverse-transition="fade-transition"
      transition="fade-transition"
      class="carouselCtrl"
      :show-arrows="!$vuetify.breakpoint.mobile"
      :height="$vuetify.breakpoint.mobile ? '215px' : '575px'"
    >
      <v-carousel-item
        v-for="(item, i) in list"
        :key="'banners_'+ i"
      >
        <v-img style="cursor: pointer; height: 100%;" :src="item.url" @click="handleClick(item)"></v-img>
      </v-carousel-item>
    </v-carousel>
    <contact-dialog :showDialog="contactDialog" type="VISION_PRO_BOOKING" @dialogCloseEvent="contactDialog = false"></contact-dialog>
  </v-card>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    model: 0,
    contactDialog: false,
  }),
  methods: {
    handleClick(item) {
      if (item.jumpStatus === 3) {
        this.contactDialog = true;
      } else {
        this.$router.push(item.jumpUrl);
      }
    },
  }
}
</script>
<style lang="scss" scoped>
::v-deep .v-carousel__controls {
  background: none;

  .v-carousel__controls__item {
    color: #000;
    opacity: 0.62;
  }
  .v-item--active {
    opacity: 1;
  }
}
</style>
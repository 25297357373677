<!--服务申报-->
<template>
  <v-dialog 
    v-model="showDialog" 
    content-class="my-custom-dialog" 
    :width="$vuetify.breakpoint.mobile ? '' : '700'" 
    :fullscreen="$vuetify.breakpoint.mobile"
    @click:outside="handleClose"
  >
    <v-card flat>
      <v-card-title class="mb-4">
        申报服务
        <span v-if="serviceId"> - {{ currentServiceName }}</span>
      </v-card-title>
      <v-card-text v-if="$vuetify.breakpoint.mobile" class="closePanel">
        <v-btn
          class="closeBtn"
          text
          @click="handleClose"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-text>
      <v-card-text>
        <v-form v-model="validForm" @submit.prevent="submit">
          <v-row>
            <v-col v-if="!serviceId" cols="12" md="3" :class="['my-0','pb-0',$vuetify.breakpoint.mobile ? '' : 'text-right']">
              <label><span style="color: red;">*</span>服务类型</label>
            </v-col>
            <v-col v-if="!serviceId" cols="12" md="9" class="my-0 py-0">
              <v-select
                name="serviceId"
                v-model="serviceForm.serviceId"
                :items="serviceList"
                item-text="name"
                item-value="_id"
                :rules="[rules.required]"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" md="3" :class="['my-0','pb-0',$vuetify.breakpoint.mobile ? '' : 'text-right']">
              <label><span style="color: red;">*</span>联系人姓名</label>
            </v-col>
            <v-col cols="12" md="9" class="my-0 py-0">
              <v-text-field
                name="contactName"
                v-model="serviceForm.contactName"
                :rules="[rules.required]"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" :class="['my-0','pb-0',$vuetify.breakpoint.mobile ? '' : 'text-right']">
              <label><span style="color: red;">*</span>联系人电话</label>
            </v-col>
            <v-col cols="12" md="9" class="my-0 py-0">
              <v-text-field
                name="contactPhone"
                v-model="serviceForm.contactPhone"
                :rules="[rules.required, rules.phonenum]"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" :class="['my-0','pb-0',$vuetify.breakpoint.mobile ? '' : 'text-right']">
              <label><span style="color: red;">*</span>联系人地址</label>
            </v-col>
            <v-col cols="12" md="9" class="my-0 py-0">
              <v-text-field
                name="contactAddress"
                v-model="serviceForm.contactAddress"
                :rules="[rules.required]"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col v-if="isRepair" cols="12" md="3" :class="['my-0','pb-0',$vuetify.breakpoint.mobile ? '' : 'text-right']">
              <label><span style="color: red;">*</span>寄送⽅式</label>
            </v-col>
            <v-col v-if="isRepair" cols="12" md="9" class="my-0 py-0">
              <v-select
                name="recoveryChannel"
                v-model="serviceForm.recoveryChannel"
                :rules="[rules.required]"
                :items="mailSends"
                item-text="name"
                item-value="type"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col v-if="isRepair" cols="12" md="3" :class="['my-0','pb-0',$vuetify.breakpoint.mobile ? '' : 'text-right']">
              <label><span style="color: red;">*</span>寄回联系⼈姓名</label>
            </v-col>
            <v-col v-if="isRepair" cols="12" md="9" class="my-0 py-0">
              <v-text-field
                name="returnsPerson"
                v-model="serviceForm.returnsPerson"
                :rules="[rules.required]"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col v-if="isRepair" cols="12" md="3" :class="['my-0','pb-0',$vuetify.breakpoint.mobile ? '' : 'text-right']">
              <label><span style="color: red;">*</span>寄回联系⼈电话</label>
            </v-col>
            <v-col v-if="isRepair" cols="12" md="9" class="my-0 py-0">
              <v-text-field
                name="returnsPhone"
                v-model="serviceForm.returnsPhone"
                :rules="[rules.required, rules.phonenum]"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col v-if="isRepair" cols="12" md="3" :class="['my-0','pb-0',$vuetify.breakpoint.mobile ? '' : 'text-right']">
              <label><span style="color: red;">*</span>寄回⽅式</label>
            </v-col>
            <v-col v-if="isRepair" cols="12" md="9" class="my-0 py-0">
              <v-select
                name="returnsChannel"
                v-model="serviceForm.returnsChannel"
                :rules="[rules.required]"
                :items="mailBacks"
                item-text="name"
                item-value="type"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col v-if="isRepair" cols="12" md="3" :class="['my-0','pb-0',$vuetify.breakpoint.mobile ? '' : 'text-right']">
              <label><span style="color: red;">*</span>寄回地址</label>
            </v-col>
            <v-col v-if="isRepair" cols="12" md="9" class="my-0 py-0">
              <v-text-field
                name="returnsAddress"
                v-model="serviceForm.returnsAddress"
                :rules="[rules.required]"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col v-if="isRepair" cols="12" md="3" :class="['my-0','pb-0',$vuetify.breakpoint.mobile ? '' : 'text-right']">
              <label><span style="color: red;">*</span>问题描述</label>
            </v-col>
            <v-col v-if="isRepair" cols="12" md="9" class="my-0 py-0">
              <v-textarea
                outlined
                name="detailFault"
                v-model="serviceForm.detailFault"
                :rules="[rules.required]"
                rows="3"
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="3" :class="['my-0','pb-0',$vuetify.breakpoint.mobile ? '' : 'text-right']">
              <label>备注</label>
            </v-col>
            <v-col cols="12" md="9" class="my-0 py-0">
              <v-textarea
                outlined
                name="remark"
                v-model="serviceForm.remark"
                rows="3"
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="3" :class="['my-0','pb-0',$vuetify.breakpoint.mobile ? '' : 'text-right']">
              <label>附件</label>
            </v-col>
            <v-col cols="12" md="9" class="my-0 py-0">
              <div class="filePanel v-text-field d-flex justify-space-between justify-center" @click="handleFileSelect">
                <label>{{ uploadName }}</label>
                <v-btn depressed small @click="handleFileSelect">{{ uploadBtn }}</v-btn>
                <input type="file" hidden ref="uploadFile" @change="handleUpload"/>
              </div>
            </v-col>
            <v-col cols="12" md="3" :class="['my-0','pb-0',$vuetify.breakpoint.mobile ? '' : 'text-right']">
              <label><span style="color: red;">*</span>序列号</label>
            </v-col>
            <v-col cols="12" md="9" class="my-0 py-0">
              <v-text-field
                v-model="serviceForm.serialNumber"
                :rules="[rules.required]"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" offset-md="3" md="9" class="mt-0 pt-0">
              <v-btn color="primary" @click="submit">提交</v-btn>
              <v-btn v-if="$vuetify.breakpoint.mobile" @click="handleClose" class="ml-4">取消</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { SET_ERROR } from "@/store/mutations.type";
import {
  mapGetters
} from "vuex";
import {
  FETCH_SERVICE_LIST,
  SERVIE_DECLARATION,
  UPLOAD
} from "@/store/actions.type";
import store from "@/store";

export default {
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    serviceId: {
      type: String,
      default: null
    }
  },
  //props: ['showDialog', 'serviceId'],
  data () {
    return {
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          if (value) {
            const pattern = /^1[3456789]\d{9}$/;
            return pattern.test(value) || '请输入正确的电话号码'
          } else {
            return true;
          }
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        email: value => {
          if (value) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || '错误的电子邮件地址'
          } else {
            return true;
          }
        },
      },
      mailSends: [
        {type: "self", name: "快递"},
        {type: "express", name: "送修"},
      ],
      mailBacks: [
        {type: "self", name: "自取"},
        {type: "express", name: "送修"},
      ],
      validForm: true,
      serviceForm: {
        serviceId: '1',
        contactName: '',
        contactPhone: '',
        contactAddress: '',

        //asset_repair
        recoveryChannel: 'self',
        returnsPerson: '',
        returnsPhone: '',
        returnsChannel: 'self',
        returnsAddress: '',
        detailFault: '',

        remark: '',
        annex: [''],
        serialNumber: '',

        mfrCode: 'ap',
        serviceType: '',
      },
      uploadName: "点击上传公司营业执照",
      uploadBtn: "选择",
    }
  },
  methods: {
    handleClose() {
      this.$emit('dialogCloseEvent', false);
    },
    loadServiceList() {
      store.dispatch(FETCH_SERVICE_LIST)
    },
    handleUpload(e) {
      if (e.target.files.length < 1) {
        return;
      }

      const param = new FormData();
      param.append('file', e.target.files[0]);

      const config = {
        headers: {'Content-Type': 'multipart/form-data'}
      };

      store.dispatch(UPLOAD, param, config)
        .then((data) => {
          if (data.data.code !== 0) {
            store.commit(SET_ERROR, {msg: data.data.message});
            return;
          }
          
          this.serviceForm.annex[0] = data.data.message;
          this.uploadName = data.data.message;
          this.uploadBtn = '重新选择';
        })
        .catch((err) => {
          store.commit(SET_ERROR, {msg: err.response.data.message});
        });
    },
    handleFileSelect() {
      this.$refs.uploadFile.click();
    },
    submit() {
      if (!this.validForm) {
        return;
      }

      this.serviceForm.serviceType = this.isRepair;

      store.dispatch(SERVIE_DECLARATION, this.serviceForm)
        .then((data) => {
          if (data.data.code !== 200) {
            store.commit(SET_ERROR, {msg: data.data.message});
            return;
          }

          this.serviceForm = {};
          this.$store.commit(SET_ERROR, {msg: '申报成功', color: 'primary'});

          this.$emit('dialogCloseEvent');
        })
        .catch((err) => {
          store.commit(SET_ERROR, {msg: err.response.data.message});
        });
    }
  },
  computed: {
    ...mapGetters(['serviceList']),
    isRepair: function() {
      for (let i=0; i<this.serviceList.length; i++) {
        const t = this.serviceList[i];
        if (this.serviceId) {
          if (t._id === this.serviceId) {
            return t.parentId.service_type === 'asset_repair';
          }
        } else {
          if (t._id === this.serviceForm.serviceId) {
            return t.parentId.service_type === 'asset_repair';
          }
        }
      }
      return false;
    },
    currentServiceName: function() {
      if (!this.serviceId) {
        return '';
      }

      for (let i=0; i<this.serviceList.length; i++) {
        const t = this.serviceList[i];
        if (t._id === this.serviceId) {
          return t.name;
        }
      }
      return '';
    },
  },
  watch: {
    showDialog(newVal, oldVal) {
      if (newVal && this.serviceId) {
        this.loadServiceList()
        this.serviceForm.serviceId = this.serviceId;
      }
    }
  },
}
</script>
<style scoped>
.my-custom-dialog {
  bottom: 0 !important;
  right: 0 !important;
  position: absolute !important;
}
.aiFrame {
  width: 100%;
  height: 100%;
  border: 0px;
  margin:0;
}

.closePanel {
  margin: 0;
  padding: 0 !important;
  height: 36px;
  overflow: hidden;
  overflow-y: hidden !important;
}

.closeBtn {
  float: right;
  padding: 0;
  color: #000 !important;
}

.aiCard {
  height: 700px;
}

.filePanel {
  width: 100%;
  border-color: rgba(0, 0, 0, .42);
  border-style: solid;
  border-width: thin;
  border-radius: 4px;
  padding-top: 2px;
  padding-bottom: 2px;

  label {
    color: rgba(0, 0, 0, 0.6);
    padding: 6px 0 6px 10px;
  }

  button {
    margin-right: 10px;
    margin-top: 3px;
  }
}

.closePanel {
  margin: 0;
  padding: 0 !important;
  height: 36px;
  overflow: hidden;
  overflow-y: hidden !important;
  position: fixed;
  top: 10px;
}

.closeBtn {
  float: right;
  padding: 0;
  color: #000 !important;
}

@media screen and (max-width:700px){
  .aiCard {
    height: 100%;
  }
}
</style>

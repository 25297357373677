// AUTH
export const CHECK_AUTH = 'checkAuth';
export const LOGIN = 'login';
export const LOGOUT = 'logout';
export const REGISTER = 'register'
export const REMOVE_AUTH = 'removeAuth'

export const FETCH_USER_INFO = 'getUserInfo';
export const EDIT_USER = 'editUser';
export const CHANGE_PASSWORD = 'changePassword';

//upload file
export const UPLOAD = 'upload'

//CONTACT
export const PUBLISH_CONTACTUS = 'createContactMessage'

// APP
export const FETCH_CATEGORY_LIST = 'getCategoryList'
export const FETCH_BANNER_LIST = 'getBannerList'

//THEME
export const CHANGE_THEME = 'changeTheme';

// OperationLog
export const PUBLISH_OPERATION_LOGS = 'batchCreateOperationLogs'

//服务申报
export const FETCH_SERVICE_LIST = 'EnterpriseServiceList'

//服务申报
export const SERVIE_DECLARATION = 'ServiceDeclaration'

//获取区域信息
export const FETCH_AREA_LIST = 'getAreaList'

//商品信息
export const FETCH_PROD_FILTER_LIST = 'getFilterListByProduct'
export const FETCH_PROD_PRICE_LIMIT = 'getPriceLimitByProduct'
export const FETCH_PROD_LIST = 'getProductList'
export const FETCH_PROD = 'getProductDetail'
export const FETCH_PROD_BRAND = 'getProductInfoByBrand'

//意向单列表
export const FETCH_INTENT_LIST = 'geIntentList'
//采购订单列表
export const FETCH_ORDER_LIST = 'getOrderList'
//采购订单详情
export const FETCH_ORDER_DETAIL = 'getOrderDetail'
//服务工单列表
export const FETCH_SERVICE_ORDER_LIST = 'getServiceOrderList'
//维修工单列表
export const FETCH_REPAIR_ORDER_LIST = 'getRepairOrderList'
//维修工单详情
export const FETCH_REPAIR_DETAIL = 'getRepairDetail'
//获取跳转token
export const FETCH_JUMP_TOKEN = "getJumpToken";

//购物及交易
export const FETCH_CART_LIST = 'getCartList'
export const ADD_PROD_CART = 'addProductToCart'
export const DEL_PROD_CART = 'removeProductToCart'
export const CREATE_NEW_ORDER = 'createNewOrder'

//知识查询
export const SEARCH_KNOWLEDGE = 'searchKnowledge'
export const FETCH_KNOWLEDGE_DETAIL = 'getKnowledgeDetail'
export const FETCH_KNOWLEDGE_LIST = 'getKnowledgeList'

//订单查询
export const SEARCH_CASE_COLLECT = 'searchCaseCollect'

//cms
export const FETCH_CMS_CONTENT = 'getCmsContent'
export const FETCH_ACTIVITY_LIST = 'getActivityList'
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-img', {
    attrs: {
      "src": _vm.fieldData(_vm.pageData, 'banner').url,
      "height": "100%",
      "width": "100%"
    }
  })], 1), _c('v-col', {
    staticClass: "d-flex justify-center mt-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "text-h3 font-weight-bold",
    staticStyle: {
      "white-space": "pre-wrap"
    }
  }, [_vm._v("解决方案")])]), _c('v-col', {
    staticClass: "d-flex justify-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "text-h5 text-center",
    staticStyle: {
      "white-space": "pre-wrap"
    }
  }, [_vm._v(_vm._s(_vm.fieldData(_vm.pageData, 'desc').value))])])], 1), _c('v-row', [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    directives: [{
      name: "log",
      rawName: "v-log",
      value: 'SOLUTIONS',
      expression: "'SOLUTIONS'"
    }],
    attrs: {
      "x-large": "",
      "rounded": "",
      "depressed": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.contactDialog = true;
      }
    }
  }, [_vm._v(" 与我们取得联系 ")])], 1)], 1), _c('v-row', {
    staticClass: "mt-12"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('v-tabs', {
    staticClass: "bordered-tabs",
    attrs: {
      "centered": "",
      "grow": "",
      "show-arrows": "",
      "color": "black"
    }
  }, [_c('v-tab', [_vm._v(" MDM 移动设备管理 ")]), _c('v-tab', [_vm._v(" 桌面虚拟化 ")]), _c('v-tab', [_vm._v(" 零信任安全 ")]), _vm._v(" " + _vm._s((_vm.tab1 = _vm.fieldData(_vm.pageData, 'tab').children[0], void 0)) + " "), _c('v-tab-item', {
    attrs: {
      "transition": "fade-transition"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', {
    staticClass: "my-8"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "d-flex justify-center mt-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "text-h5 black--text font-weight-bold",
    staticStyle: {
      "white-space": "pre-wrap"
    }
  }, [_vm._v("MDM 移动设备管理")])]), _c('v-col', {
    staticClass: "text-md-h5 my-4 grey--text",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.fieldData(_vm.tab1, 'desc').value) + " ")])])], 1), _c('v-row', [_c('v-col', {
    staticClass: "text-h5",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.fieldData(_vm.tab1, 'mm-title').title))])]), _c('v-col', {
    staticClass: "text-body-1 my-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.fieldData(_vm.tab1, 'mm-title').value))])]), _c('v-col', {
    staticClass: "my-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1), _vm._l(_vm.fieldData(_vm.tab1, 'mm-list').children, function (item, index) {
    return [_c('v-col', {
      attrs: {
        "cols": "1"
      }
    }, [_c('img', {
      staticClass: "mdm-image",
      attrs: {
        "src": item.children[0].url
      }
    })]), _c('v-col', {
      attrs: {
        "cols": "11"
      }
    }, [_c('span', {
      staticClass: "text-h6"
    }, [_vm._v(_vm._s(item.children[0].title))]), _c('p', {
      staticClass: "text-body-1"
    }, [_vm._v(" " + _vm._s(item.children[0].value) + " ")])])];
  })], 2), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-tabs', {
    staticClass: "bordered-tabs",
    attrs: {
      "centered": "",
      "grow": "",
      "show-arrows": "",
      "color": "black"
    }
  }, [_c('v-tab', [_vm._v(" VMware ")]), _c('v-tab', [_vm._v(" Jamf ")]), _vm._v(" " + _vm._s((_vm.vmTab = _vm.fieldData(_vm.tab1, 'tab').children[0], void 0)) + " "), _c('v-tab-item', {
    attrs: {
      "transition": "fade-transition"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', [_c('v-row', [_c('v-col', {
    staticClass: "d-flex justify-center my-8",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "text-h5 black--text font-weight-bold"
  }, [_vm._v("VMware")])]), _c('v-col', {
    staticClass: "d-flex justify-center text-md-h5 my-4 grey--text",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(_vm._s(_vm.fieldData(_vm.vmTab, 'desc').value))])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "10",
      "offset-md": "1"
    }
  }, [_c('v-img', {
    attrs: {
      "src": _vm.fieldData(_vm.vmTab, 'banner').url
    }
  })], 1), _c('v-col', {
    staticClass: "text-h6",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.fieldData(_vm.vmTab, 'plat').title))])]), _c('v-col', {
    staticClass: "text-body-1",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.fieldData(_vm.vmTab, 'plat').value) + " ")])]), _vm._l(_vm.fieldData(_vm.vmTab, 'plat-list').children, function (item, index) {
    return [_c('v-col', {
      attrs: {
        "cols": "2"
      }
    }, [_c('img', {
      staticClass: "vm-image",
      attrs: {
        "src": item.children[0].url
      }
    })]), _c('v-col', {
      attrs: {
        "cols": "10"
      }
    }, [_c('span', {
      staticClass: "text-h6"
    }, [_vm._v(_vm._s(item.children[0].title))]), _c('p', {
      staticClass: "text-body-1"
    }, [_vm._v(" " + _vm._s(item.children[0].value) + " ")])])];
  })], 2)], 1)], 1)], 1), _vm._v(" " + _vm._s((_vm.jamfTab = _vm.fieldData(_vm.tab1, 'tab').children[1], void 0)) + " "), _c('v-tab-item', {
    attrs: {
      "transition": "fade-transition"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', [_c('v-row', [_c('v-col', {
    staticClass: "d-flex justify-center my-8",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "text-h5 black--text font-weight-bold"
  }, [_vm._v("Jamf")])]), _c('v-col', {
    staticClass: "mb-8",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('p', {
    staticClass: "text-h6 text-md-h5 font-weight-bold mt-4 mb-8"
  }, [_vm._v(" " + _vm._s(_vm.fieldData(_vm.jamfTab, 'desc1-title').value) + " ")]), _c('p', {
    staticClass: "text-subtitle-1 my-4 grey--text"
  }, [_vm._v(" " + _vm._s(_vm.fieldData(_vm.jamfTab, 'desc1').value) + " ")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-img', {
    attrs: {
      "src": _vm.fieldData(_vm.jamfTab, 'desc1').url
    }
  })], 1), _c('v-col', {
    staticClass: "mb-8",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-img', {
    attrs: {
      "src": _vm.fieldData(_vm.jamfTab, 'desc2').url
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('span', {
    staticClass: "text-h6 text-md-h5 font-weight-bold mt-4 mb-8"
  }, [_vm._v(" " + _vm._s(_vm.fieldData(_vm.jamfTab, 'desc2').title) + " ")]), _c('p', {
    staticClass: "text-subtitle-1 my-4 grey--text"
  }, [_vm._v(" " + _vm._s(_vm.fieldData(_vm.jamfTab, 'desc2').value) + " ")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('img', {
    staticClass: "jamf-image",
    attrs: {
      "src": _vm.fieldData(_vm.jamfTab, 'jamf-pro').url
    }
  })]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "text-h6"
  }, [_vm._v(_vm._s(_vm.fieldData(_vm.jamfTab, 'jamf-pro').title))])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "text-body-1 grey--text"
  }, [_vm._v(" " + _vm._s(_vm.fieldData(_vm.jamfTab, 'jamf-pro').value) + " ")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('img', {
    staticClass: "jamf-image",
    attrs: {
      "src": _vm.fieldData(_vm.jamfTab, 'jamf-connect').url
    }
  })]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "text-h6"
  }, [_vm._v(_vm._s(_vm.fieldData(_vm.jamfTab, 'jamf-connect').title))])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "text-body-1 grey--text"
  }, [_vm._v(" " + _vm._s(_vm.fieldData(_vm.jamfTab, 'jamf-connect').value) + " ")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('img', {
    staticClass: "jamf-image",
    attrs: {
      "src": _vm.fieldData(_vm.jamfTab, 'jamf-protect').url
    }
  })]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "text-h6"
  }, [_vm._v(_vm._s(_vm.fieldData(_vm.jamfTab, 'jamf-protect').title))])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "text-body-1 grey--text"
  }, [_vm._v(" " + _vm._s(_vm.fieldData(_vm.jamfTab, 'jamf-protect').value) + " ")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('img', {
    staticClass: "jamf-image",
    attrs: {
      "src": _vm.fieldData(_vm.jamfTab, 'jamf-pa').url
    }
  })]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "text-h6"
  }, [_vm._v(_vm._s(_vm.fieldData(_vm.jamfTab, 'jamf-pa').title))])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "text-body-1 grey--text"
  }, [_vm._v(" " + _vm._s(_vm.fieldData(_vm.jamfTab, 'jamf-pa').value) + " ")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "text-h6"
  }, [_vm._v(_vm._s(_vm.fieldData(_vm.jamfTab, 'jamf-td').title))])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "text-body-1 grey--text"
  }, [_vm._v(" " + _vm._s(_vm.fieldData(_vm.jamfTab, 'jamf-td').value) + " ")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "text-h6"
  }, [_vm._v(_vm._s(_vm.fieldData(_vm.jamfTab, 'jamf-dp').title))])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "text-body-1 grey--text"
  }, [_vm._v(" " + _vm._s(_vm.fieldData(_vm.jamfTab, 'jamf-dp').value) + " ")])])], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1), _vm._v(" " + _vm._s((_vm.tab2 = _vm.fieldData(_vm.pageData, 'tab').children[1], void 0)) + " "), _c('v-tab-item', {
    attrs: {
      "transition": "fade-transition"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', {
    staticClass: "my-4"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "d-flex justify-center mt-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "text-h5 black--text font-weight-bold",
    staticStyle: {
      "white-space": "pre-wrap"
    }
  }, [_vm._v("桌面虚拟化")])]), _c('v-col', {
    staticClass: "text-md-h5 my-4 grey--text",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.fieldData(_vm.tab2, 'desc').value) + " ")])]), _c('v-col', {
    staticClass: "text-h6",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.fieldData(_vm.tab2, 'list-title').value))])]), _vm._l(_vm.fieldData(_vm.tab2, 'app-list').children, function (item, index) {
    return [_c('v-col', {
      attrs: {
        "cols": "1"
      }
    }, [_c('img', {
      staticClass: "mdm-image",
      attrs: {
        "src": item.children[0].url
      }
    })]), _c('v-col', {
      attrs: {
        "cols": "11"
      }
    }, [_c('span', {
      staticClass: "text-h6"
    }, [_vm._v(_vm._s(item.children[0].title))]), _c('p', {
      staticClass: "text-body-1 my-4 grey--text"
    }, [_vm._v(" " + _vm._s(item.children[0].value) + " ")])])];
  })], 2), _c('v-row', [_c('v-col', {
    staticClass: "text-h6 mb-12",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.fieldData(_vm.tab2, 'vr-title').value))])]), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "text-h6 text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(_vm._s(_vm.fieldData(_vm.tab2, 'vr-list').children[0].children[0].value))])]), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-icon', {
    attrs: {
      "large": ""
    }
  }, [_vm._v("keyboard_arrow_down")])], 1)], 1)], 1), _c('v-col', {
    staticClass: "text-body-1 grey--text",
    attrs: {
      "cols": "9"
    }
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.fieldData(_vm.tab2, 'vr-list').children[0].children[1].value) + " ")])]), _c('v-col', {
    staticClass: "text-h6 text-center",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v(" " + _vm._s(_vm.fieldData(_vm.tab2, 'vr-list').children[1].children[0].title) + " ")]), _c('v-col', {
    staticClass: "text-body-1 grey--text",
    attrs: {
      "cols": "9"
    }
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.fieldData(_vm.tab2, 'vr-list').children[1].children[0].value) + " ")])]), _c('v-col', {
    staticClass: "text-h6 text-center",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v(" " + _vm._s(_vm.fieldData(_vm.tab2, 'vr-list').children[2].children[0].title) + " ")]), _c('v-col', {
    staticClass: "text-body-1 grey--text",
    attrs: {
      "cols": "9"
    }
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.fieldData(_vm.tab2, 'vr-list').children[2].children[0].value) + " ")])]), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-icon', {
    attrs: {
      "large": ""
    }
  }, [_vm._v("keyboard_arrow_down")])], 1), _c('v-col', {
    staticClass: "text-h6 text-center",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" " + _vm._s(_vm.fieldData(_vm.tab2, 'vr-list').children[3].children[0].title) + " ")])], 1)], 1), _c('v-col', {
    staticClass: "text-body-1 grey--text",
    attrs: {
      "cols": "9"
    }
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.fieldData(_vm.tab2, 'vr-list').children[3].children[0].value) + " ")])])], 1), _c('v-row', [_c('v-col', {
    staticClass: "text-h6",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" " + _vm._s(_vm.fieldData(_vm.tab2, 'vr-desc').title) + " ")]), _c('v-col', {
    staticClass: "text-body-1 my-4 grey--text",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.fieldData(_vm.tab2, 'vr-desc').value) + " ")])])], 1)], 1)], 1)], 1), _vm._v(" " + _vm._s((_vm.tab3 = _vm.fieldData(_vm.pageData, 'tab').children[2], void 0)) + " "), _c('v-tab-item', {
    attrs: {
      "transition": "fade-transition"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', {
    staticClass: "mt-4 mb-4"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "d-flex justify-center mt-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "text-h5 black--text font-weight-bold",
    staticStyle: {
      "white-space": "pre-wrap"
    }
  }, [_vm._v("零信任安全")])]), _c('v-col', {
    staticClass: "text-md-h5 my-4 grey--text",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.fieldData(_vm.tab3, 'desc').value) + " ")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-img', {
    attrs: {
      "src": _vm.fieldData(_vm.tab3, 'image1').url
    }
  })], 1), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" " + _vm._s(_vm.fieldData(_vm.tab3, 'image1').value) + " ")]), _c('v-col', {
    staticClass: "text-md-h5 my-4 grey--text",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.fieldData(_vm.tab3, 'desc2').value) + " ")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-img', {
    attrs: {
      "src": _vm.fieldData(_vm.tab3, 'image2').url
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('contact-dialog', {
    attrs: {
      "showDialog": _vm.contactDialog,
      "type": "SOLUTIONS"
    },
    on: {
      "dialogCloseEvent": function dialogCloseEvent($event) {
        _vm.contactDialog = false;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
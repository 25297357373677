import ApiService from "@/common/http";
import {AppService } from "@/common/http";
import JwtService from "@/common/jwt";
import SocketService from '@/common/socket';
import {
  LOGIN,
  LOGOUT,
  CHECK_AUTH,
  REGISTER,
  FETCH_USER_INFO,
  EDIT_USER,
  CHANGE_PASSWORD,
  REMOVE_AUTH
} from "./actions.type";
import { SET_AUTH, SET_USER, SET_USERINFO, PURGE_AUTH, SET_ERROR } from "./mutations.type";

const state = {
  userInfo: JwtService.getUser(),
  employer: {},
  isAuthenticated: !!JwtService.getToken(),
  routes: [],
};

const getters = {
  currentUser: state => state?.userInfo,
  currentEmployer: state => state.employer,
  isAuthenticated: state => state.isAuthenticated,
  currentRoutes: state => state.routes,
};

const actions = {
  [LOGIN](context, param) {
    return new Promise((resolve, reject) => {
      // let form = new FormData();
      // form.append('username', credentials.username);
      // form.append('password', credentials.password);
      // let param = new URLSearchParams(form);

      ApiService.post("double-rise/sys/login", param)
        .then(({ data }) => {
          if (data.code !== 200) {
            context.commit(SET_ERROR, { msg: data.message });
            reject(data);
            return;
          }
          context.commit(SET_AUTH, data.result.token);
          context.commit(SET_USERINFO, data.result.userInfo);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
  [REGISTER](context, param) {
    return ApiService.put("double-rise/enterpriseUser/register", param);

    // return new Promise((resolve, reject) => {
    //   AppService.put("enterpriseUser/register", param);
    //     .then(({ data }) => {
    //       if (data.code !== 200) {
    //         context.commit(SET_ERROR, { msg: data.message });
    //         reject(data);
    //         return;
    //       }
    //       context.commit(SET_AUTH, data.result.token);
    //       resolve(data);
    //     })
    //     .catch(({ response }) => {
    //       reject(response.data);
    //     });
    // });
  },
  [CHECK_AUTH](context) {
    return new Promise((resolve, reject) => {
      AppService.getAuth("enterpriseUser/queryById")
        .then(({ data }) => {
          //context.commit(SET_USER, data.result);
          resolve(getters.currentUser())
        })
        .catch(({ response }) => {
          context.commit(PURGE_AUTH);
          context.commit(SET_ERROR, response.data.message);
          reject(response.data)
        });
    });
  },
  [REMOVE_AUTH](context) {
    context.commit(PURGE_AUTH);
  },
  [LOGOUT](context) {
    return new Promise(resolve => {
      // context.commit(SET_LOGS, { operator: state.user._id, action: 'Logout', objType: 'Account', objId: [state.user._id] });
      context.commit(PURGE_AUTH)
      resolve()
    })
  },
  [FETCH_USER_INFO](context, params) {
    return AppService.queryAuth("enterpriseUser/queryById", params);
  },
  [EDIT_USER](context, param) {
    return AppService.postAuth("enterpriseUser/edit", param);
  },
  [CHANGE_PASSWORD](context, param) {
    return AppService.postAuth("enterpriseUser/updatePassword", param);
  },
};

const mutations = {
  [SET_AUTH](state, token) {
    state.isAuthenticated = true;
    JwtService.saveToken(token);

  },
  [SET_USER](state, data) {
    let arrPermission = [];
    data.employer.roles.forEach(item => {
      arrPermission.push(item.permission)
    })
    state.user = Object.assign(data, { permission: [...new Set(arrPermission.flat())] });
    state.employer = data.employer;
  },
  [SET_USERINFO](state, data) {
    JwtService.saveUser(data);
    state.userInfo = JwtService.getUser();
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.employer = null;
    JwtService.destroyUser();
    JwtService.destroyToken();
    SocketService.disconnect();
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};

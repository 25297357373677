<template>
  <v-container v-if="pageData">
    <v-row>
      <v-col cols="12">
        <v-img :src="fieldData(pageData, 'banner').url" width="100%" contain></v-img>
      </v-col>
      <v-col cols="12" class="d-flex justify-center mt-4">
        <span class="text-h3 font-weight-bold" style="white-space: pre-wrap">{{ fieldData(pageData, 'desc').title }}</span>
      </v-col>
      <v-col cols="12" class="d-flex justify-center">
        <p class="text-h5 text-center" style="white-space: pre-wrap">{{ fieldData(pageData, 'desc').value }}</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-center">
        <v-btn
          x-large
          rounded
          depressed
          color="primary"
          v-log="'SALES_SUPPORT'"
          @click="contactDialog = true"
        >
          与我们取得联系
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-12">
      <v-col cols="12" md="12" lg="12">
        <v-tabs centered show-arrows color="black" class="bordered-tabs">
          <v-tab>ESC企业解决方案中心</v-tab>
          <v-tab>ESP企业服务平台</v-tab>
          <v-tab>EC企业员工优选商城</v-tab>

          <!--ESC企业解决方案中心-->
          <v-tab-item transition="fade-transition">
            <v-card flat>
              <v-card-text class="my-8">
                <v-row class="justify-center">
                  <v-col cols="12" class="text-center">
                    <span class="text-h5 black--text font-weight-bold" style="white-space: pre-wrap">ESC企业解决方案中心</span>
                  </v-col>
                  <v-col cols="12" class="text-center">
                    <p class="text-subtitle-1 text-center" style="white-space: pre-wrap">{{ fieldData(tabContext[0], 'desc').value }}</p>
                  </v-col>

                  <v-col cols="6" lg="2" md="2" v-for="(item, index) in fieldData(tabContext[0], 'list').children" :key="'tabIcon_'+ index">
                    <v-card flat>
                      <v-card-text class="text-center justify-center">
                        <v-img :src="item.children[0].url" max-height="45px" contain></v-img>
                        <p class="text-subtitle-1">{{ item.children[0].title }}</p>
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <v-col cols="12">
                    <v-alert type="success" outlined text>{{ fieldData(tabContext[0], 'desc2').value }}</v-alert>
                  </v-col>

                  <v-col cols="12">
                    <v-expansion-panels
                      v-model="activeTab"
                      multiple
                    >
                      <v-expansion-panel
                        v-for="(cc, index) in fieldData(tabContext[0], 'address').children"
                        :key="'city_'+ index"
                      >
                        <v-expansion-panel-header>{{ cc.title }}</v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <p v-for="(c, ci) in cc.children" :key="'ci_'+ ci" v-html="c.value"></p>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>


<!--                  <v-col cols="12">-->
<!--                    <v-slide-group-->
<!--                      v-model="activeTab"-->
<!--                      center-active-->
<!--                      show-arrows-->
<!--                    >-->
<!--                      <v-slide-item v-for="(cc, index) in fieldData(tabContext[0], 'address').children" :key="'city_'+ index" v-slot="{ active, toggle }" :ripple="false">-->
<!--                        <v-card class="ma-4" height="350" width="350" hover @click="toggle" outlined :ripple="false">-->
<!--                          <v-card-title>{{ cc.title }}</v-card-title>-->
<!--                          <v-card-text>-->
<!--                            <p v-for="(c, ci) in cc.children" :key="'ci_'+ ci" v-html="c.value"></p>-->
<!--                          </v-card-text>-->
<!--                        </v-card>-->
<!--                      </v-slide-item>-->
<!--                    </v-slide-group>-->
<!--                  </v-col>-->

                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!--ESP企业服务平台-->
          <v-tab-item transition="fade-transition">
            <v-card flat>
              <v-card-text class="my-8">
                <v-row class="justify-center">
                  <v-col cols="12" class="d-flex justify-center mt-4">
                    <span class="text-h5 black--text font-weight-bold" style="white-space: pre-wrap">ESP企业服务平台</span>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-center">
                    <p class="text-subtitle-1 text-center" style="white-space: pre-wrap">{{ fieldData(tabContext[1], 'desc').value }}</p>
                  </v-col>

                  <v-col cols="6" lg="2" md="2" v-for="(item, index) in fieldData(tabContext[1], 'list').children" :key="'tabIcon_'+ index">
                    <v-card flat>
                      <v-card-text class="text-center justify-center">
                        <v-img :src="item.children[0].url" max-height="45px" contain></v-img>
                        <p class="text-subtitle-1">{{ item.children[0].title }}</p>
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <v-col cols="12">
                    <v-alert type="success" outlined text>{{ fieldData(tabContext[1], 'desc2').value }}</v-alert>
                  </v-col>

                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!--EC企业员工优选商城-->
          <v-tab-item transition="fade-transition">
            <v-card flat>
              <v-card-text class="my-8">
                <v-row class="justify-center">
                  <v-col cols="12" class="d-flex justify-center mt-4">
                    <span class="text-h5 black--text font-weight-bold" style="white-space: pre-wrap">EC企业员工优选商城</span>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-center">
                    <p class="text-subtitle-1 text-center" style="white-space: pre-wrap">{{ fieldData(tabContext[2], 'desc').value }}</p>
                  </v-col>

                  <v-col cols="6" lg="2" md="2" v-for="(item, index) in fieldData(tabContext[2], 'list').children" :key="'tabIcon_'+ index">
                    <v-card flat>
                      <v-card-text class="text-center justify-center">
                        <v-img :src="item.children[0].url" max-height="45px" contain></v-img>
                        <p class="text-subtitle-1">{{ item.children[0].title }}</p>
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <v-col cols="12">
                    <v-alert type="success" outlined text>{{ fieldData(tabContext[2], 'desc2').value }}</v-alert>
                  </v-col>

                  <v-col cols="12" class="text-center">
                    <v-img :src="fieldData(tabContext[2], 'buy-img').url" />
                  </v-col>

                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
    <contact-dialog :showDialog="contactDialog" type="SALES_SUPPORT" @dialogCloseEvent="contactDialog = false"></contact-dialog>
  </v-container>
</template>
<script>
import { SET_ERROR } from "@/store/mutations.type";
import {
  FETCH_CMS_CONTENT,
} from "@/store/actions.type";
import store from "@/store";

export default {
  data() {
    return {
      pageData: {},
      contactDialog: false,
      activeTab: null,
      tabContext: [],
    }
  },
  computed: {
    fieldData() {
      return function(data, name) {
        for (const field of data.children) {
          if (field.uname === name) {
            return field;
          }
        }
        return null;
      };
    },
  },
  methods: {
    initPageData() {
      store.dispatch(FETCH_CMS_CONTENT, {name: 'sales-support', version: this.$route.query.version || ''})
        .then((data) => {
          this.pageData = data.data.result.tree;
          this.tabContext = this.fieldData(data.data.result.tree, 'tab').children
          this.$forceUpdate();
        })
        .catch((err) => {
          store.commit(SET_ERROR, {msg: err.response.data.message});
        });
    }
  },
  created() {
    this.initPageData();
  },
}
</script>
<style lang="scss" scoped>
.container p {
  white-space: pre-line;
}
.bordered-tabs >>> .v-slide-group__content {
  border-bottom: 1px solid #0000001e;
}
.v-tab {
  text-transform: none !important;
}
</style>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-img', {
    attrs: {
      "src": _vm.fieldData(_vm.pageData, 'banner').url,
      "height": "100%",
      "width": "100%"
    }
  })], 1), _c('v-col', {
    staticClass: "d-flex justify-center mt-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "text-h3 font-weight-bold",
    staticStyle: {
      "white-space": "pre-wrap"
    }
  }, [_vm._v("售后和管理")])]), _c('v-col', {
    staticClass: "d-flex justify-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "text-h5 text-center",
    staticStyle: {
      "white-space": "pre-wrap"
    }
  }, [_vm._v(_vm._s(_vm.fieldData(_vm.pageData, 'sub-title').value))])])], 1), _c('v-row', [_c('v-col', {
    staticClass: "text-center mb-12",
    attrs: {
      "cols": "12"
    }
  }, [!_vm.isAuthenticated ? _c('v-menu', {
    attrs: {
      "transition": "scroll-y-transition",
      "min-width": "100",
      "nudge-width": 100,
      "top": "",
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "rounded-xl large-btn",
          attrs: {
            "x-large": "",
            "depressed": "",
            "color": "primary"
          }
        }, 'v-btn', attrs, false), on), [_vm._v(" 马上申报服务 ")])];
      }
    }], null, false, 4074791369)
  }, [_c('v-card', [_c('v-card-text', [_vm._v(" 请登录您的专属账户 ")]), _c('v-card-actions', {
    staticClass: "text-center"
  }, [_c('v-btn', {
    attrs: {
      "to": {
        path: '/account'
      },
      "depressed": "",
      "plat": ""
    }
  }, [_vm._v("注册")]), _c('v-btn', {
    attrs: {
      "to": {
        path: '/login'
      },
      "depressed": "",
      "plat": ""
    }
  }, [_vm._v("登录")])], 1)], 1)], 1) : _vm._e(), _vm.isAuthenticated ? _c('v-btn', {
    directives: [{
      name: "log",
      rawName: "v-log",
      value: 'AFTER_SALES_WITH_SERVICE',
      expression: "'AFTER_SALES_WITH_SERVICE'"
    }],
    staticClass: "rounded-xl large-btn",
    attrs: {
      "x-large": "",
      "depressed": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.showServiceDialog = true;
      }
    }
  }, [_vm._v(" 马上申报服务 ")]) : _vm._e(), _c('contact-service', {
    attrs: {
      "showDialog": _vm.showServiceDialog
    },
    on: {
      "dialogCloseEvent": function dialogCloseEvent($event) {
        _vm.showServiceDialog = false;
      }
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('v-tabs', {
    staticClass: "bordered-tabs",
    attrs: {
      "centered": "",
      "grow": "",
      "show-arrows": "",
      "color": "black"
    }
  }, [_c('v-tab', [_vm._v(" 运营维护 ")]), _c('v-tab', [_vm._v(" 设备部署 ")]), _c('v-tab', [_vm._v(" 检测维修 ")]), _vm._v(" " + _vm._s((_vm.tab1 = _vm.fieldData(_vm.pageData, 'tab').children[0], void 0)) + " "), _c('v-tab-item', {
    attrs: {
      "transition": "fade-transition"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', {
    staticClass: "mt-4 mb-4"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "d-flex justify-center mt-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "text-h5 black--text font-weight-bold",
    staticStyle: {
      "white-space": "pre-wrap"
    }
  }, [_vm._v("运营维护")])]), _c('v-col', {
    staticClass: "text-md-h5 my-4 grey--text text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.fieldData(_vm.tab1, 'title').value) + " ")])])], 1), _c('v-row', [_c('v-col', {
    staticClass: "text-h6 mt-4 mb-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.fieldData(_vm.tab1, 'service').title))])]), _c('v-col', {
    staticClass: "text-body-1 grey--text",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.fieldData(_vm.tab1, 'service').value) + " ")])])], 1), _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card', {
    staticClass: "d-flex justify-space-between align-center",
    attrs: {
      "flat": ""
    }
  }, _vm._l(_vm.fieldData(_vm.tab1, 'service-list').children, function (item, index) {
    return _c('img', {
      key: index,
      staticClass: "operImg",
      attrs: {
        "src": item.children[0].url
      }
    });
  }), 0), _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-stepper', {
    attrs: {
      "flat": "",
      "vertical": true
    }
  }, [_c('v-stepper-header', {
    staticClass: "elevation-0"
  }, [_c('v-stepper-step', {
    staticClass: "text-center",
    attrs: {
      "step": "",
      "color": "#b2b2b2"
    }
  }), _c('v-divider'), _c('v-stepper-step', {
    attrs: {
      "step": "",
      "color": "#b2b2b2"
    }
  }), _c('v-divider'), _c('v-stepper-step', {
    attrs: {
      "step": "",
      "color": "#b2b2b2"
    }
  }), _c('v-divider'), _c('v-stepper-step', {
    attrs: {
      "step": "",
      "color": "#b2b2b2"
    }
  }), _c('v-divider'), _c('v-stepper-step', {
    attrs: {
      "step": "",
      "color": "#b2b2b2"
    }
  })], 1)], 1)], 1), _c('v-card', {
    staticClass: "d-flex justify-space-between",
    attrs: {
      "flat": ""
    }
  }, _vm._l(_vm.fieldData(_vm.tab1, 'service-list').children, function (item, index) {
    return _c('v-card', {
      key: index,
      attrs: {
        "flat": ""
      }
    }, [_c('span', {
      staticClass: "text-h6"
    }, [_vm._v(_vm._s(item.children[0].title))]), _c('p', {
      staticClass: "text-body-1 grey--text"
    }, [_vm._v(" " + _vm._s(item.children[0].value) + " ")])]);
  }), 1)], 1)], 1)], 1)], 1), _vm._v(" " + _vm._s((_vm.tab2 = _vm.fieldData(_vm.pageData, 'tab').children[1], void 0)) + " "), _c('v-tab-item', {
    attrs: {
      "transition": "fade-transition"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', {
    staticClass: "mt-4 mb-4"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "d-flex justify-center mt-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "text-h5 black--text font-weight-bold",
    staticStyle: {
      "white-space": "pre-wrap"
    }
  }, [_vm._v("设备部署")])]), _c('v-col', {
    staticClass: "text-h6 my-4 grey--text text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.fieldData(_vm.tab1, 'title').value) + " ")])])], 1), _c('v-row', _vm._l(_vm.fieldData(_vm.tab2, 'image-list').children, function (item, index) {
    return _c('v-col', {
      key: index,
      staticClass: "text-h6 text-center",
      attrs: {
        "cols": "12",
        "md": "4"
      }
    }, [_c('span', [_c('v-img', {
      staticClass: "image-deploy",
      staticStyle: {
        "margin": "auto"
      },
      attrs: {
        "src": item.children[0].url
      }
    })], 1), _c('span', {
      staticStyle: {
        "line-height": "40px"
      }
    }, [_vm._v(_vm._s(item.children[0].title))])]);
  }), 1), _c('div', {
    staticClass: "page-content my-8",
    domProps: {
      "innerHTML": _vm._s(_vm.fieldData(_vm.tab2, 'desc').value)
    }
  })], 1)], 1)], 1), _vm._v(" " + _vm._s((_vm.tab3 = _vm.fieldData(_vm.pageData, 'tab').children[2], void 0)) + " "), _c('v-tab-item', {
    attrs: {
      "transition": "fade-transition"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', {
    staticClass: "mt-4 mb-4"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "d-flex justify-center mt-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "text-h5 black--text font-weight-bold",
    staticStyle: {
      "white-space": "pre-wrap"
    }
  }, [_vm._v("检测维修")])]), _c('v-col', {
    staticClass: "text-md-h5 my-4 grey--text text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_c('v-img', {
    staticClass: "white--text",
    attrs: {
      "src": _vm.fieldData(_vm.tab3, 'banner').url
    }
  })], 1)])], 1), _c('v-row', [_c('v-col', {
    staticClass: "text-body-1 grey--text",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.fieldData(_vm.tab3, 'desc').value) + " ")])]), _c('v-col', {
    staticClass: "text-body-1 grey--text text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.fieldData(_vm.tab3, 'list-title').value) + " ")])]), _vm._l(_vm.fieldData(_vm.tab3, 'image-list').children, function (item, index) {
    return [index <= 3 ? _c('v-col', {
      staticClass: "text-body-1 grey--text text-center",
      attrs: {
        "cols": "3"
      }
    }, [_c('img', {
      staticClass: "image-main",
      attrs: {
        "src": item.children[0].url
      }
    }), _c('p', [_vm._v(" " + _vm._s(item.children[0].title) + " ")])]) : _vm._e(), index > 3 ? _c('v-col', {
      staticClass: "text-body-1 grey--text text-center",
      attrs: {
        "cols": "4"
      }
    }, [_c('img', {
      staticClass: "image-main",
      attrs: {
        "src": item.children[0].url
      }
    }), _c('p', [_vm._v(" " + _vm._s(item.children[0].title) + " ")])]) : _vm._e()];
  })], 2)], 1)], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
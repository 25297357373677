var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-system-bar', {
    attrs: {
      "app": "",
      "color": "secondary",
      "height": "64"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-img', {
    staticClass: "v-list-item--link",
    attrs: {
      "src": "/static/logoword.svg",
      "contain": "",
      "max-height": "40",
      "max-width": _vm.$vuetify.breakpoint.mobile ? '100' : ''
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push('/');
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-img', {
    staticClass: "appleLogo",
    attrs: {
      "src": _vm.logo_auth,
      "contain": "",
      "max-height": "40"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.pageData ? _c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-img', {
    attrs: {
      "src": _vm.fieldData(_vm.pageData, 'banner').url,
      "width": "100%",
      "contain": ""
    }
  })], 1), _c('v-col', {
    staticClass: "d-flex justify-center mt-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "text-h3 font-weight-bold",
    staticStyle: {
      "white-space": "pre-wrap"
    }
  }, [_vm._v(_vm._s(_vm.fieldData(_vm.pageData, 'desc').title))])]), _c('v-col', {
    staticClass: "d-flex justify-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "text-h5 text-center",
    staticStyle: {
      "white-space": "pre-wrap"
    }
  }, [_vm._v(_vm._s(_vm.fieldData(_vm.pageData, 'desc').value))])])], 1), _c('v-row', [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    directives: [{
      name: "log",
      rawName: "v-log",
      value: 'SALES_SUPPORT',
      expression: "'SALES_SUPPORT'"
    }],
    attrs: {
      "x-large": "",
      "rounded": "",
      "depressed": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.contactDialog = true;
      }
    }
  }, [_vm._v(" 与我们取得联系 ")])], 1)], 1), _c('v-row', {
    staticClass: "mt-12"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('v-tabs', {
    staticClass: "bordered-tabs",
    attrs: {
      "centered": "",
      "show-arrows": "",
      "color": "black"
    }
  }, [_c('v-tab', [_vm._v("ESC企业解决方案中心")]), _c('v-tab', [_vm._v("ESP企业服务平台")]), _c('v-tab', [_vm._v("EC企业员工优选商城")]), _c('v-tab-item', {
    attrs: {
      "transition": "fade-transition"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', {
    staticClass: "my-8"
  }, [_c('v-row', {
    staticClass: "justify-center"
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "text-h5 black--text font-weight-bold",
    staticStyle: {
      "white-space": "pre-wrap"
    }
  }, [_vm._v("ESC企业解决方案中心")])]), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "text-subtitle-1 text-center",
    staticStyle: {
      "white-space": "pre-wrap"
    }
  }, [_vm._v(_vm._s(_vm.fieldData(_vm.tabContext[0], 'desc').value))])]), _vm._l(_vm.fieldData(_vm.tabContext[0], 'list').children, function (item, index) {
    return _c('v-col', {
      key: 'tabIcon_' + index,
      attrs: {
        "cols": "6",
        "lg": "2",
        "md": "2"
      }
    }, [_c('v-card', {
      attrs: {
        "flat": ""
      }
    }, [_c('v-card-text', {
      staticClass: "text-center justify-center"
    }, [_c('v-img', {
      attrs: {
        "src": item.children[0].url,
        "max-height": "45px",
        "contain": ""
      }
    }), _c('p', {
      staticClass: "text-subtitle-1"
    }, [_vm._v(_vm._s(item.children[0].title))])], 1)], 1)], 1);
  }), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-alert', {
    attrs: {
      "type": "success",
      "outlined": "",
      "text": ""
    }
  }, [_vm._v(_vm._s(_vm.fieldData(_vm.tabContext[0], 'desc2').value))])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-expansion-panels', {
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, _vm._l(_vm.fieldData(_vm.tabContext[0], 'address').children, function (cc, index) {
    return _c('v-expansion-panel', {
      key: 'city_' + index
    }, [_c('v-expansion-panel-header', [_vm._v(_vm._s(cc.title))]), _c('v-expansion-panel-content', _vm._l(cc.children, function (c, ci) {
      return _c('p', {
        key: 'ci_' + ci,
        domProps: {
          "innerHTML": _vm._s(c.value)
        }
      });
    }), 0)], 1);
  }), 1)], 1)], 2)], 1)], 1)], 1), _c('v-tab-item', {
    attrs: {
      "transition": "fade-transition"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', {
    staticClass: "my-8"
  }, [_c('v-row', {
    staticClass: "justify-center"
  }, [_c('v-col', {
    staticClass: "d-flex justify-center mt-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "text-h5 black--text font-weight-bold",
    staticStyle: {
      "white-space": "pre-wrap"
    }
  }, [_vm._v("ESP企业服务平台")])]), _c('v-col', {
    staticClass: "d-flex justify-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "text-subtitle-1 text-center",
    staticStyle: {
      "white-space": "pre-wrap"
    }
  }, [_vm._v(_vm._s(_vm.fieldData(_vm.tabContext[1], 'desc').value))])]), _vm._l(_vm.fieldData(_vm.tabContext[1], 'list').children, function (item, index) {
    return _c('v-col', {
      key: 'tabIcon_' + index,
      attrs: {
        "cols": "6",
        "lg": "2",
        "md": "2"
      }
    }, [_c('v-card', {
      attrs: {
        "flat": ""
      }
    }, [_c('v-card-text', {
      staticClass: "text-center justify-center"
    }, [_c('v-img', {
      attrs: {
        "src": item.children[0].url,
        "max-height": "45px",
        "contain": ""
      }
    }), _c('p', {
      staticClass: "text-subtitle-1"
    }, [_vm._v(_vm._s(item.children[0].title))])], 1)], 1)], 1);
  }), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-alert', {
    attrs: {
      "type": "success",
      "outlined": "",
      "text": ""
    }
  }, [_vm._v(_vm._s(_vm.fieldData(_vm.tabContext[1], 'desc2').value))])], 1)], 2)], 1)], 1)], 1), _c('v-tab-item', {
    attrs: {
      "transition": "fade-transition"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', {
    staticClass: "my-8"
  }, [_c('v-row', {
    staticClass: "justify-center"
  }, [_c('v-col', {
    staticClass: "d-flex justify-center mt-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "text-h5 black--text font-weight-bold",
    staticStyle: {
      "white-space": "pre-wrap"
    }
  }, [_vm._v("EC企业员工优选商城")])]), _c('v-col', {
    staticClass: "d-flex justify-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "text-subtitle-1 text-center",
    staticStyle: {
      "white-space": "pre-wrap"
    }
  }, [_vm._v(_vm._s(_vm.fieldData(_vm.tabContext[2], 'desc').value))])]), _vm._l(_vm.fieldData(_vm.tabContext[2], 'list').children, function (item, index) {
    return _c('v-col', {
      key: 'tabIcon_' + index,
      attrs: {
        "cols": "6",
        "lg": "2",
        "md": "2"
      }
    }, [_c('v-card', {
      attrs: {
        "flat": ""
      }
    }, [_c('v-card-text', {
      staticClass: "text-center justify-center"
    }, [_c('v-img', {
      attrs: {
        "src": item.children[0].url,
        "max-height": "45px",
        "contain": ""
      }
    }), _c('p', {
      staticClass: "text-subtitle-1"
    }, [_vm._v(_vm._s(item.children[0].title))])], 1)], 1)], 1);
  }), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-alert', {
    attrs: {
      "type": "success",
      "outlined": "",
      "text": ""
    }
  }, [_vm._v(_vm._s(_vm.fieldData(_vm.tabContext[2], 'desc2').value))])], 1), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-img', {
    attrs: {
      "src": _vm.fieldData(_vm.tabContext[2], 'buy-img').url
    }
  })], 1)], 2)], 1)], 1)], 1)], 1)], 1)], 1), _c('contact-dialog', {
    attrs: {
      "showDialog": _vm.contactDialog,
      "type": "SALES_SUPPORT"
    },
    on: {
      "dialogCloseEvent": function dialogCloseEvent($event) {
        _vm.contactDialog = false;
      }
    }
  })], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    directives: [{
      name: "dialogDrag",
      rawName: "v-dialogDrag"
    }],
    staticClass: "aiContainer",
    attrs: {
      "title": "",
      "visible": _vm.dialogVisible,
      "fullscreen": _vm.$vuetify.breakpoint.mobile,
      "width": "616px",
      "before-close": _vm.handleClose,
      "append-to-body": true,
      "lock-scroll": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c('iframe', {
    staticClass: "aiFrame",
    attrs: {
      "id": "frame",
      "src": _vm.aiUrl
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-system-bar
    app
    color="secondary"
    height="64"
  >
    <v-row>
      <v-col cols="6">
        <v-img
          src="/static/logoword.svg"
          contain
          max-height="40"
          :max-width="$vuetify.breakpoint.mobile ? '100' : ''"
          @click="$router.push('/')"
          class="v-list-item--link"
        ></v-img>
      </v-col>
      <v-col cols="6"><v-img class="appleLogo" :src="logo_auth" contain max-height="40"></v-img></v-col>
    </v-row>
  </v-system-bar>
</template>
<script>
export default {
  computed: {
    // eslint-disable-next-line
    logo_auth () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '/static/agent_logo_sm.png'
        case 'sm': return '/static/agent_logo_sm.png'
        case 'md': return '/static/agent_logo.png'
        case 'lg': return '/static/agent_logo.png'
        case 'xl': return '/static/agent_logo.png'
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@media screen and (max-width:700px){
  ::v-deep .appleLogo {
    .v-image__image {
      margin-left: auto;
      right: 0px;
      width: 120px;
    }
    .v-responsive__content {
      display: none;
    }
  }
}

</style>
<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-img :src="fieldData(pageData, 'banner').url" height="100%" width="100%"></v-img>
      </v-col>
      <v-col cols="12" class="d-flex justify-center mt-4">
        <span class="text-h3 font-weight-bold" style="white-space: pre-wrap">解决方案</span>
      </v-col>
      <v-col cols="12" class="d-flex justify-center">
        <p class="text-h5 text-center" style="white-space: pre-wrap">{{ fieldData(pageData, 'desc').value }}</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-center">
        <v-btn
          x-large
          rounded
          depressed
          color="primary"
          v-log="'SOLUTIONS'"
          @click="contactDialog = true"
        >
          与我们取得联系
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="mt-12">
      <v-col cols="12" md="12" lg="12">
        <v-tabs
          centered grow show-arrows color="black" class="bordered-tabs"
        >
          <v-tab>
            MDM 移动设备管理
          </v-tab>
          <v-tab>
            桌面虚拟化
          </v-tab>
          <v-tab>
            零信任安全
          </v-tab>

          {{ (tab1 = fieldData(pageData, 'tab').children[0], void 0) }}
          <!--MDM 移动设备管理-->
          <v-tab-item transition="fade-transition">
            <v-card flat>
              <v-card-text class="my-8">
                <v-row>
                  <v-col cols="12" class="d-flex justify-center mt-4">
                    <span class="text-h5 black--text font-weight-bold" style="white-space: pre-wrap">MDM 移动设备管理</span>
                  </v-col>
                  <v-col cols="12" class="text-md-h5 my-4 grey--text">
                    <p>
                      {{ fieldData(tab1, 'desc').value }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="text-h5">
                    <span>{{ fieldData(tab1, 'mm-title').title }}</span>
                  </v-col>
                  <v-col cols="12" class="text-body-1 my-0">
                    <span>{{ fieldData(tab1, 'mm-title').value }}</span>
                  </v-col>
                  <v-col cols="12" class="my-4">
                    <v-divider></v-divider>
                  </v-col>
                  <template v-for="(item, index) in fieldData(tab1, 'mm-list').children">
                    <v-col cols="1">
                      <img class="mdm-image" :src="item.children[0].url">
                    </v-col>
                    <v-col cols="11">
                      <span class="text-h6">{{ item.children[0].title }}</span>
                      <p class="text-body-1">
                        {{ item.children[0].value }}
                      </p>
                    </v-col>
                  </template>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-tabs
                      centered grow show-arrows color="black" class="bordered-tabs"
                    >
                      <v-tab>
                        VMware
                      </v-tab>
                      <v-tab>
                        Jamf
                      </v-tab>

                      {{ (vmTab = fieldData(tab1, 'tab').children[0], void 0) }}
                      <!--VMware-->
                      <v-tab-item transition="fade-transition">
                        <v-card flat>
                          <v-card-text>
                            <v-row>
                              <v-col cols="12" class="d-flex justify-center my-8">
                                <span class="text-h5 black--text font-weight-bold">VMware</span>
                              </v-col>
                              <v-col cols="12" class="d-flex justify-center text-md-h5 my-4 grey--text">
                                <p>{{ fieldData(vmTab, 'desc').value }}</p>
                              </v-col>
                              <v-col cols="12" md="10" offset-md="1">
                                <v-img :src="fieldData(vmTab, 'banner').url" />
                              </v-col>
                              <v-col cols="12" class="text-h6">
                                <span>{{ fieldData(vmTab, 'plat').title }}</span>
                              </v-col>
                              <v-col cols="12" class="text-body-1">
                                <p>
                                  {{ fieldData(vmTab, 'plat').value }}
                                </p>
                              </v-col>
                              <template v-for="(item, index) in fieldData(vmTab, 'plat-list').children">
                                <v-col cols="2">
                                  <img class="vm-image" :src="item.children[0].url" />
                                </v-col>
                                <v-col cols="10" >
                                  <span class="text-h6">{{ item.children[0].title }}</span>
                                  <p class="text-body-1">
                                    {{ item.children[0].value }}
                                  </p>
                                </v-col>
                              </template>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>

                      {{ (jamfTab = fieldData(tab1, 'tab').children[1], void 0) }}
                      <!--Jamf-->
                      <v-tab-item transition="fade-transition">
                        <v-card flat>
                          <v-card-text>
                            <v-row>
                              <v-col cols="12" class="d-flex justify-center my-8">
                                <span class="text-h5 black--text font-weight-bold">Jamf</span>
                              </v-col>
                              <v-col cols="12" md="6" class="mb-8">
                                <p class="text-h6 text-md-h5 font-weight-bold mt-4 mb-8">
                                  {{ fieldData(jamfTab, 'desc1-title').value }}
                                </p>
                                <p class="text-subtitle-1 my-4 grey--text">
                                  {{ fieldData(jamfTab, 'desc1').value }}
                                </p>
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-img :src="fieldData(jamfTab, 'desc1').url" />
                              </v-col>
                              <v-col cols="12" md="6" class="mb-8">
                                <v-img :src="fieldData(jamfTab, 'desc2').url" />
                              </v-col>
                              <v-col cols="12" md="6">
                                <span class="text-h6 text-md-h5 font-weight-bold mt-4 mb-8">
                                  {{ fieldData(jamfTab, 'desc2').title }}
                                </span>
                                <p class="text-subtitle-1 my-4 grey--text">
                                  {{ fieldData(jamfTab, 'desc2').value }}
                                </p>
                              </v-col>

                              <v-col cols="12">
                                <img class="jamf-image" :src="fieldData(jamfTab, 'jamf-pro').url" />
                              </v-col>
                              <v-col cols="12">
                                <span class="text-h6">{{ fieldData(jamfTab, 'jamf-pro').title }}</span>
                              </v-col>
                              <v-col cols="12">
                                <p class="text-body-1 grey--text">
                                  {{ fieldData(jamfTab, 'jamf-pro').value }}
                                </p>
                              </v-col>

                              <v-col cols="12">
                                <img class="jamf-image" :src="fieldData(jamfTab, 'jamf-connect').url" />
                              </v-col>
                              <v-col cols="12">
                                <span class="text-h6">{{ fieldData(jamfTab, 'jamf-connect').title }}</span>
                              </v-col>
                              <v-col cols="12">
                                <p class="text-body-1 grey--text">
                                  {{ fieldData(jamfTab, 'jamf-connect').value }}
                                </p>
                              </v-col>

                              <v-col cols="12">
                                <img class="jamf-image" :src="fieldData(jamfTab, 'jamf-protect').url" />
                              </v-col>
                              <v-col cols="12">
                                <span class="text-h6">{{ fieldData(jamfTab, 'jamf-protect').title }}</span>
                              </v-col>
                              <v-col cols="12">
                                <p class="text-body-1 grey--text">
                                  {{ fieldData(jamfTab, 'jamf-protect').value }}
                                </p>
                              </v-col>

                              <v-col cols="12">
                                <img class="jamf-image" :src="fieldData(jamfTab, 'jamf-pa').url" />
                              </v-col>
                              <v-col cols="12">
                                <span class="text-h6">{{ fieldData(jamfTab, 'jamf-pa').title }}</span>
                              </v-col>
                              <v-col cols="12">
                                <p class="text-body-1 grey--text">
                                  {{ fieldData(jamfTab, 'jamf-pa').value }}
                                </p>
                              </v-col>
                              <v-col cols="12">
                                <span class="text-h6">{{ fieldData(jamfTab, 'jamf-td').title }}</span>
                              </v-col>
                              <v-col cols="12">
                                <p class="text-body-1 grey--text">
                                  {{ fieldData(jamfTab, 'jamf-td').value }}
                                </p>
                              </v-col>
                              <v-col cols="12">
                                <span class="text-h6">{{ fieldData(jamfTab, 'jamf-dp').title }}</span>
                              </v-col>
                              <v-col cols="12">
                                <p class="text-body-1 grey--text">
                                  {{ fieldData(jamfTab, 'jamf-dp').value }}
                                </p>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                    </v-tabs>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          {{ (tab2 = fieldData(pageData, 'tab').children[1], void 0) }}
          <!--桌面虚拟化-->
          <v-tab-item transition="fade-transition">
            <v-card flat>
              <v-card-text class="my-4">
                <v-row>
                  <v-col cols="12" class="d-flex justify-center mt-4">
                    <span class="text-h5 black--text font-weight-bold" style="white-space: pre-wrap">桌面虚拟化</span>
                  </v-col>
                  <v-col cols="12" class="text-md-h5 my-4 grey--text">
                    <p>
                      {{ fieldData(tab2, 'desc').value }}
                    </p>
                  </v-col>
                  <v-col cols="12" class="text-h6">
                    <span>{{ fieldData(tab2, 'list-title').value }}</span>
                  </v-col>

                  <template v-for="(item, index) in fieldData(tab2, 'app-list').children">
                    <v-col cols="1">
                      <img class="mdm-image" :src="item.children[0].url">
                    </v-col>
                    <v-col cols="11">
                      <span class="text-h6">{{ item.children[0].title }}</span>
                      <p class="text-body-1 my-4 grey--text">
                        {{ item.children[0].value }}
                      </p>
                    </v-col>
                  </template>
                </v-row>
                <v-row>
                  <v-col cols="12" class="text-h6 mb-12">
                    <span>{{ fieldData(tab2, 'vr-title').value }}</span>
                  </v-col>
                  <v-col cols="3">
                    <v-row>
                      <v-col cols="12" class="text-h6 text-center">
                        <p>{{ fieldData(tab2, 'vr-list').children[0].children[0].value }}</p>
                      </v-col>
                      <v-col cols="12" class="text-center">
                        <v-icon large>keyboard_arrow_down</v-icon>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="9" class="text-body-1 grey--text">
                    <p>
                      {{ fieldData(tab2, 'vr-list').children[0].children[1].value }}
                    </p>
                  </v-col>
                  <v-col cols="3" class="text-h6 text-center">
                    {{ fieldData(tab2, 'vr-list').children[1].children[0].title }}
                  </v-col>
                  <v-col cols="9" class="text-body-1 grey--text">
                    <p>
                      {{ fieldData(tab2, 'vr-list').children[1].children[0].value }}
                    </p>
                  </v-col>
                  <v-col cols="3" class="text-h6 text-center">
                    {{ fieldData(tab2, 'vr-list').children[2].children[0].title }}
                  </v-col>
                  <v-col cols="9" class="text-body-1 grey--text">
                    <p>
                      {{ fieldData(tab2, 'vr-list').children[2].children[0].value }}
                    </p>
                  </v-col>
                  <v-col cols="3">
                    <v-row>
                      <v-col cols="12" class="text-center">
                        <v-icon large>keyboard_arrow_down</v-icon>
                      </v-col>
                      <v-col cols="12" class="text-h6 text-center">
                        {{ fieldData(tab2, 'vr-list').children[3].children[0].title }}
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="9" class="text-body-1 grey--text">
                    <p>
                      {{ fieldData(tab2, 'vr-list').children[3].children[0].value }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="text-h6">
                    {{ fieldData(tab2, 'vr-desc').title }}
                  </v-col>
                  <v-col cols="12" class="text-body-1 my-4 grey--text">
                    <p>
                      {{ fieldData(tab2, 'vr-desc').value }}
                    </p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          {{ (tab3 = fieldData(pageData, 'tab').children[2], void 0) }}
          <!--零信任安全-->
          <v-tab-item transition="fade-transition">
            <v-card flat>
              <v-card-text class="mt-4 mb-4">
                <v-row>
                  <v-col cols="12" class="d-flex justify-center mt-4">
                    <span class="text-h5 black--text font-weight-bold" style="white-space: pre-wrap">零信任安全</span>
                  </v-col>
                  <v-col cols="12" class="text-md-h5 my-4 grey--text">
                    <p>
                      {{ fieldData(tab3, 'desc').value }}
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <v-img :src="fieldData(tab3, 'image1').url" />
                  </v-col>
                  <v-col cols="12" class="text-center">
                    {{ fieldData(tab3, 'image1').value }}
                  </v-col>
                  <v-col cols="12" class="text-md-h5 my-4 grey--text">
                    <p>
                      {{ fieldData(tab3, 'desc2').value }}
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <v-img :src="fieldData(tab3, 'image2').url" />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
    <contact-dialog :showDialog="contactDialog" type="SOLUTIONS" @dialogCloseEvent="contactDialog = false"></contact-dialog>
  </v-container>
</template>

<style lang="scss" scoped>
.container p {
  white-space: pre-line;
}
.page-btn {
  font-weight: 400;
  font-size: 24px;
}

.page-list {
  li {
    list-style: none;
  }
}

.activity-title {
  margin-left: auto;
  margin-right: auto;
}
.large-btn {
  width: 300px;
  font-size: 24px;
}
.tab-title {
  font-weight: 500;
  font-size: 24px;
  padding: 10px 40px;
  border-bottom: 1px solid #d9d9d9;
}

.tab-mobile-title {
  padding: 10px 10px;
  border-bottom: 1px solid #d9d9d9;
}
.grey-block {
  background-color: #f5f5f7;
  color: #0573d9;
  border-radius: 24px;
  padding: 30px 40px;
}

.mdm-image {
  height: 60px;
  width: 60px;
  object-fit: contain;
}
.jamf-image {
  height: 59px;
  margin-bottom: 20px;
}

.vm-image {
  width: 124px;
}

@media screen and (max-width:700px){
  .mdm-image {
    height: 30px;
    width: 30px;
  }
  .jamf-image {
    height: 40px;
  }

  .vm-image {
    width: 50px;
  }

  .text-pretty {
    word-break: keep-all;
    word-wrap: break-word;
  }
}
</style>

<script>
import { SET_ERROR } from "@/store/mutations.type";
import {
  FETCH_CMS_CONTENT,
} from "@/store/actions.type";
import store from "@/store";

export default {
  data() {
    return {
      contactDialog: false,
      pageData: {},
    }
  },
  methods: {
    initPageData() {
      store.dispatch(FETCH_CMS_CONTENT, {name: 'solution', version: this.$route.query.version || ''})
        .then((data) => {
          this.pageData = data.data.result.tree;
        })
        .catch((err) => {
          store.commit(SET_ERROR, {msg: err.response.data.message});
        });
    }
  },
  created() {
    this.initPageData();
  },
  computed: {
    fieldData() {
      return function(data, name) {
        for (const field of data.children) {
          if (field.uname === name) {
            return field;
          }
        }
        return null;
      };
    },
  },
}
</script>

import {
  PUBLISH_OPERATION_LOGS
} from "@/store/actions.type";
import router from '@/router'
import { v4 as uuidv4 } from 'uuid';
import store from "@/store";
import { getUser } from "@/common/jwt"


// 用户操作埋点
class OperationLog {
  //操作类型
  operationType = {
    visit: "PAGE_ACCESS", //访问
    click: "BUTTON_CLICK", //点击
  }

  constructor() {
    this.timeOut = null // 定时ID
    this.timeNum = 60000 // 定时毫秒
    this.localKeyName = 'aapl_log' // 本地存储KEY
    this.uuid = this.getUserId()
    this.screen = this.getScreen()
    this.pathName = this.getPathAndName()
  }

  start() {
    this.visibilityWindow()
    this.timer()
  }

  // 发送后台
  async pushData() {
    const localData = (JSON.parse(localStorage.getItem(this.localKeyName)) || []);
    if (localData.length) {
      //await store.dispatch(PUBLISH_OPERATION_LOGS, localData)
      const blob = new Blob([JSON.stringify(localData)], {type : 'application/json'});
      navigator.sendBeacon(process.env.VUE_APP_BASE_API + '/double-rise/visitLog/batch', blob);

      this.clearLog()
    }
  }

  // 监测窗口变化（锁屏、关闭等）
  visibilityWindow() {
    document.addEventListener('visibilitychange', () => {
      console.log(document.visibilityState);

      // 离开页面
      if (document.visibilityState !== 'visible') {
        this.pushData()

        if (window.pageView) {
          const pageViewTime = new Date() - window.pageView.startTime;
          window.pageView.viewTime = pageViewTime;
          // console.log(`Page view time 2 for ${from.name}: ${pageViewTime}ms`);
      
          this.sendPageView(window.pageView);
        }

        
      } else {
        // 访问页面
        this.saveLog('visit', router.currentRoute.path)
      }
    });

    window.addEventListener('unload', () => {
      endVisitLog(router.currentRoute.path);
    });
  }

  //定时器
  timer() {
    if (this.timeOut) {
      clearTimeout(this.timeOut)
      this.timeOut = null
    }
    this.timeOut = setTimeout(() => {
      this.pushData()
      this.timer()
    }, this.timeNum)
  }

  // 写本地日志
  saveLog(type, opration, from, to, param) {
    const user = getUser();

    // 生成数据记录
    const params = {
      uuid: this.uuid,
      userId: user != null ? user.id : "",
      enterpriseId: user != null ? user.enterpriseId : "",
      source: "",
      channel: "",
      client: this.screen,
      ua: navigator.userAgent,
      pageUrl: "",
      eventType: type,
      eventName: "",
      eventParams: "",
      eventKeyParam: "",
      eventResult: "",
      visitTime: new Date().toISOString()
    }
    // 获取本地存储记录
    const localData = (JSON.parse(localStorage.getItem(this.localKeyName)) || []);

    // 访问页面
    if (type === 'visit') {
      if (to !== undefined && to !== null) {
        // 查找页面标识
        const path = to?.path || ''
        const source = from?.meta?.operation_name || 'DIRECT'
        const eventName = to?.meta?.operation_name || 'DIRECT'

        params.pageUrl = path
        params.source = source
        params.channel = to?.query?.channel || ''
        params.eventName = eventName
        //商品统计
        //eventParams为商品大类（Mac\iPhone...）
        //eventKeyParam为具体商品 (MacBook Pro\iPhone 15...)
        if (eventName === 'PRODUCT_BRAND_PAGE') {
          params.eventParams = to?.params?.brand || ''
        }
        if (eventName === 'PRODUCT_CATEGORY_PAGE') {
          params.eventParams = to?.params?.brand || ''
          params.eventKeyParam = to?.params?.category || ''
        }
        if (eventName === 'PRODUCT_CATEGORY_DETAIL') {
          params.eventParams = to?.params?.brand || ''
          params.eventKeyParam = to?.params?.product || ''
        }
      }
    } else if (type === 'click') {
      params.eventName = opration
      if (param !== undefined) {
        params.eventKeyParam = param;
      }
    }

    // 校验重复
    const repeat = localData.some(item => {
      return item.visitTime === params.visitTime && item.eventName === params.eventName && item.eventType === params.eventType
    })

    if (!repeat) {
      localData.push(params)
      localStorage.setItem(this.localKeyName, JSON.stringify(localData))
    }
  }

  // 清空日志
  clearLog() {
    localStorage.removeItem(this.localKeyName)
  }

  // 生成用户唯一ID
  generateId() {
    const uuid = uuidv4();
    localStorage.setItem('uuid', uuid)
    return uuid
  }
  // 获取用户唯一ID
  getUserId() {
    return localStorage.getItem('uuid') || this.generateId()
  }

  // 获取屏幕
  getScreen() {
    let screenType = 'pc'
    if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
      screenType =  'h5'
    }
    return screenType
  }

  // 获取所有路由并生成一维数组
  getPathAndName() {
    const allRoutes = router.getRoutes()
    return Object.keys(allRoutes).map(key => {
      return {
        path: allRoutes[key].path || '/',
        name: allRoutes[key].meta.operation_name
      }
    });
  }

  startLog(type, opration, from, to) {
    const user = getUser();

    // 生成数据记录
    const params = {
      uuid: this.uuid,
      userId: user != null ? user.id : "",
      enterpriseId: user != null ? user.enterpriseId : "",
      source: "",
      channel: "",
      client: this.screen,
      ua: navigator.userAgent,
      pageUrl: "",
      eventType: type,
      eventName: "",
      eventParams: "",
      eventKeyParam: "",
      eventResult: "",
      visitTime: new Date().toISOString()
    }

    // 查找页面标识
    const path = to?.path || ''
    const source = from?.meta?.operation_name || 'DIRECT'
    const eventName = to?.meta?.operation_name || 'DIRECT'

    params.startTime = new Date();
    params.pageUrl = path
    params.source = source
    params.channel = to?.query?.channel || ''
    params.eventName = eventName
    //商品统计
    //eventParams为商品大类（Mac\iPhone...）
    //eventKeyParam为具体商品 (MacBook Pro\iPhone 15...)
    if (eventName === 'PRODUCT_BRAND_PAGE') {
      params.eventParams = to?.params?.brand || ''
    }
    if (eventName === 'PRODUCT_CATEGORY_PAGE') {
      params.eventParams = to?.params?.brand || ''
      params.eventKeyParam = to?.params?.category || ''
    }
    if (eventName === 'PRODUCT_CATEGORY_DETAIL') {
      params.eventParams = to?.params?.brand || ''
      params.eventKeyParam = to?.params?.product || ''
    }

    window.pageView = params;
  }

  sendPageView(params) {
    const blob = new Blob([JSON.stringify(params)], {type : 'application/json'});
    navigator.sendBeacon(process.env.VUE_APP_BASE_API + '/double-rise/visitLog/write', blob);
    //window.pageView = null;
  }

}
const operation = new OperationLog()

export function start() {
  operation.start()
}

export function writeLog(type, content, from, to, param) {
  operation.saveLog(type, content, from, to, param)
}

export function startVisitLog(url, from, to) {
  if (from.name && window.pageView) {
    const pageViewTime = new Date() - window.pageView.startTime;
    window.pageView.viewTime = pageViewTime;
    //console.log(`Page view time for ${from.name}: ${pageViewTime}ms`);
    operation.sendPageView(window.pageView);
  }

  // if (to === undefined) {
  //   return;
  // }
  operation.startLog('visit', null, from, to)
}

export function endVisitLog(path) {
  if (window.pageView) {
    const pageViewTime = new Date() - window.pageView.startTime;
    window.pageView.viewTime = pageViewTime;
    console.log(`Page view time 2 for ${from.name}: ${pageViewTime}ms`);

    operation.sendPageView(window.pageView);
  }
}

export function getClientType() {
  return operation.getScreen();
}
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('v-card', {
    staticClass: "supportBanner",
    attrs: {
      "flat": ""
    }
  }, [_c('img', {
    staticClass: "white--text align-end",
    attrs: {
      "src": "/static/get-support/know-banner.png"
    }
  })])], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "mt-8 mb-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "text-center text-h4 text-md-h3 font-weight-bold mt-4"
  }, [_vm._v("获取支持")])]), _c('v-col', {
    staticClass: "mb-16",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "text-center text-md-h5 my-4 grey--text"
  }, [_vm._v(" 请描述您目前的具体问题，我们将提供针对"), _c('br'), _vm._v(" 描述适用的解决方案；并提供 Apple 产品"), _c('br'), _vm._v(" 相关的知识内容。 ")])])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('v-tabs', {
    attrs: {
      "centered": "",
      "plain": "",
      "transition": "fade-transition",
      "prev-icon": "chevron_left",
      "next-icon": "chevron_right",
      "show-arrows": ""
    }
  }, [_c('v-tab', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']
  }, [_vm._v(" 百科知识 ")]), _c('v-tab', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']
  }, [_vm._v(" 服务订单 ")]), _c('v-tab', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']
  }, [_vm._v(" 采购订单 ")]), _c('v-tab-item', {
    attrs: {
      "transition": "fade-transition"
    }
  }, [_c('v-card', {
    staticClass: "mt-8",
    attrs: {
      "flat": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "offset-md": "2",
      "md": "8"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "请输入您遇到的问题或想要了解的内容",
      "outlined": "",
      "hide-details": "",
      "prepend-inner-icon": "search"
    },
    on: {
      "input": _vm.handleSearchKnow
    },
    model: {
      value: _vm.knowSearch.key,
      callback: function callback($$v) {
        _vm.$set(_vm.knowSearch, "key", $$v);
      },
      expression: "knowSearch.key"
    }
  })], 1), _vm.knowSearch.show ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "searchResult"
  }, [_c('div', [_c('div', {
    staticClass: "resultTitle"
  }, [_vm._v("快速链接")]), _vm._l(_vm.knowSearch.quickLinks, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "resultFont",
      on: {
        "click": function click($event) {
          return _vm.handleNavigate(item.id);
        }
      }
    }, [_vm._v(_vm._s(item.title))]);
  })], 2), _c('v-divider', {
    staticClass: "my-4"
  }), _c('div', [_c('div', {
    staticClass: "resultTitle"
  }, [_vm._v("搜索建议")]), _vm._l(_vm.knowSearch.suggestions, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "resultFont",
      on: {
        "click": function click($event) {
          return _vm.handleSelect(item);
        }
      }
    }, [_vm._v(_vm._s(item))]);
  })], 2)], 1)]) : _vm._e(), _vm.knowSearch.showSuggestion ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "sugList"
  }, [_c('div', {
    staticClass: "selectItem"
  }, [_vm._v(_vm._s(_vm.knowSearch.suggestionText))]), _vm._l(_vm.knowSearch.suggestionList, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "sugListItem",
      on: {
        "click": function click($event) {
          return _vm.handleNavigate(item.id);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": item.profileImage
      }
    }), _c('div', {
      staticClass: "flex flex-1 flex-col"
    }, [_c('div', {
      staticClass: "sugTop"
    }, [_vm._v(_vm._s(item.title))]), _c('div', {
      staticClass: "sugBottom"
    }, [_c('span', [_vm._v(_vm._s(item.catalog))]), _vm._v(" 更新时间：" + _vm._s(item.updateTime) + " ")])])]);
  })], 2)]) : _vm._e()], 1)], 1)], 1)], 1)], 1), _c('v-tab-item', {
    attrs: {
      "transition": "fade-transition"
    }
  }, [_c('v-card', {
    staticClass: "mt-8",
    attrs: {
      "flat": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "offset-md": "2",
      "md": "8"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "请输入您的订单编号",
      "outlined": "",
      "hide-details": "",
      "prepend-inner-icon": "search"
    },
    on: {
      "input": _vm.handleSearchService
    },
    model: {
      value: _vm.serviceSearch.key,
      callback: function callback($$v) {
        _vm.$set(_vm.serviceSearch, "key", $$v);
      },
      expression: "serviceSearch.key"
    }
  })], 1), _vm.serviceSearch.show ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "searchResult orderFadeIn"
  }, [_c('div', [_c('div', {
    staticClass: "resultTitle"
  }, [_vm._v("搜索结果")]), !_vm.isAuthenticated ? _c('div', {
    staticClass: "loginTip"
  }, [_c('a', {
    attrs: {
      "href": "/login"
    }
  }, [_vm._v("登录")]), _vm._v(" 查看你的订单 ")]) : _vm._e(), _vm.isAuthenticated ? _c('div', _vm._l(_vm.serviceSearch.result, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "resultFont",
      on: {
        "click": function click($event) {
          return _vm.handleOrderDetail(item.id, item.type, item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.orderNumber || item.caseNumber) + " ")]);
  }), 0) : _vm._e()])])]) : _vm._e()], 1)], 1)], 1)], 1)], 1), _c('v-tab-item', {
    attrs: {
      "transition": "fade-transition"
    }
  }, [_c('v-card', {
    staticClass: "mt-8",
    attrs: {
      "flat": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "offset-md": "2",
      "md": "8"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "请输入您的订单编号",
      "outlined": "",
      "hide-details": "",
      "prepend-inner-icon": "search"
    },
    on: {
      "input": _vm.handleSearchOrder
    },
    model: {
      value: _vm.orderSearch.key,
      callback: function callback($$v) {
        _vm.$set(_vm.orderSearch, "key", $$v);
      },
      expression: "orderSearch.key"
    }
  })], 1), _vm.orderSearch.show ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "searchResult orderFadeIn"
  }, [_c('div', [_c('div', {
    staticClass: "resultTitle"
  }, [_vm._v("搜索结果")]), !_vm.isAuthenticated ? _c('div', {
    staticClass: "loginTip"
  }, [_c('a', {
    attrs: {
      "href": "/login"
    }
  }, [_vm._v("登录")]), _vm._v(" 查看你的订单 ")]) : _vm._e(), _vm.isAuthenticated ? _c('div', _vm._l(_vm.orderSearch.result, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "resultFont",
      on: {
        "click": function click($event) {
          return _vm.handleOrderDetail(item.id, 'order', item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.orderNumber || item.caseNumber) + " ")]);
  }), 0) : _vm._e()])])]) : _vm._e(), _c('order-order-detail', {
    attrs: {
      "showDialog": _vm.showOrderDialog,
      "orderId": _vm.currentOrderId
    },
    on: {
      "dialogCloseEvent": function dialogCloseEvent($event) {
        _vm.showOrderDialog = false;
      }
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('v-card', {
    staticClass: "contactCustomer",
    attrs: {
      "flat": ""
    }
  }, [_c('img', {
    staticClass: "contactCustomerImg",
    attrs: {
      "src": "/static/get-support/knowbg.png"
    }
  }), _c('div', {
    staticClass: "contactCustomerTitle"
  }, [_vm._v("在线视频客服")]), _c('div', {
    staticClass: "contactCustomerSubTitle"
  }, [_vm._v("如您的问题未被解答，请在线呼叫产品专家，面对面的为您提供帮助。")]), _c('a', {
    attrs: {
      "href": "https://baseavs.udesk.cn/h5/?sdkAppId=psSRYZX3iY8NF5hm&code=",
      "target": "_blank"
    }
  }, [_c('div', {
    staticClass: "contactCustomerBtn"
  }, [_c('img', {
    attrs: {
      "src": "/img/icons/camera.png",
      "alt": ""
    }
  }), _c('div', {
    staticClass: "contactCustomerBtnTxt"
  }, [_vm._v("拨打视频获取支持")])])]), _c('div', {
    staticClass: "contactCustomerTip"
  }, [_vm._v("我们的工作时间为：每周一至周五 9:00～18:00")])])], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "mt-8 mb-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "text-center page-h1 mt-4"
  }, [_vm._v("线上服务申报")])]), _c('v-col', {
    staticClass: "mb-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "text-center text-h6 font-weight-medium mb-2"
  }, [_vm._v(" 如问题已解决并需要获得维修服务，您可以在线进行服务申报。 ")])]), _c('v-col', {
    staticClass: "text-center mb-12",
    attrs: {
      "cols": "12"
    }
  }, [!_vm.isAuthenticated ? _c('v-menu', {
    attrs: {
      "transition": "scroll-y-transition",
      "min-width": "100",
      "nudge-width": 100,
      "top": "",
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "rounded-xl large-btn",
          attrs: {
            "x-large": "",
            "depressed": "",
            "color": "primary"
          }
        }, 'v-btn', attrs, false), on), [_vm._v(" 马上申报服务 ")])];
      }
    }], null, false, 4074791369)
  }, [_c('v-card', [_c('v-card-text', [_vm._v(" 请登录您的专属账户 ")]), _c('v-card-actions', {
    staticClass: "text-center"
  }, [_c('v-btn', {
    attrs: {
      "to": {
        path: '/account'
      },
      "depressed": "",
      "plat": ""
    }
  }, [_vm._v("注册")]), _c('v-btn', {
    attrs: {
      "to": {
        path: '/login'
      },
      "depressed": "",
      "plat": ""
    }
  }, [_vm._v("登录")])], 1)], 1)], 1) : _vm._e(), _vm.isAuthenticated ? _c('v-btn', {
    directives: [{
      name: "log",
      rawName: "v-log",
      value: 'GET_SUPPORT_WITH_SERVICE',
      expression: "'GET_SUPPORT_WITH_SERVICE'"
    }],
    staticClass: "rounded-xl large-btn",
    attrs: {
      "x-large": "",
      "depressed": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.showServiceDialog = true;
      }
    }
  }, [_vm._v(" 马上申报服务 ")]) : _vm._e()], 1), _c('contact-service', {
    attrs: {
      "showDialog": _vm.showServiceDialog
    },
    on: {
      "dialogCloseEvent": function dialogCloseEvent($event) {
        _vm.showServiceDialog = false;
      }
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
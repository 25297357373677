var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.pageData ? _c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-img', {
    attrs: {
      "src": _vm.fieldData(_vm.pageData, 'banner').url,
      "width": "100%",
      "contain": ""
    }
  })], 1), _c('v-col', {
    staticClass: "d-flex justify-center mt-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "text-h3 font-weight-bold",
    staticStyle: {
      "white-space": "pre-wrap"
    }
  }, [_vm._v(_vm._s(_vm.fieldData(_vm.pageData, 'title').value))])]), _c('v-col', {
    staticClass: "d-flex justify-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "text-h5 text-center",
    staticStyle: {
      "white-space": "pre-wrap"
    }
  }, [_vm._v(_vm._s(_vm.fieldData(_vm.pageData, 'sub-title').value))])])], 1), _c('v-row', [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    directives: [{
      name: "log",
      rawName: "v-log",
      value: 'DEVICE_LEASING',
      expression: "'DEVICE_LEASING'"
    }],
    staticClass: "rounded-xl large-btn",
    attrs: {
      "x-large": "",
      "rounded": "",
      "depressed": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.contactDialog = true;
      }
    }
  }, [_vm._v(" 与我们取得联系 ")])], 1)], 1), _c('v-row', {
    staticClass: "mt-12"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('v-tabs', {
    staticClass: "bordered-tabs",
    attrs: {
      "centered": "",
      "show-arrows": "",
      "color": "black"
    }
  }, [_c('v-tab', [_vm._v("MDaaS 租赁服务")]), _c('v-tab', [_vm._v("客户案例介绍")]), _c('v-tab-item', {
    attrs: {
      "transition": "fade-transition"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', {
    staticClass: "my-8"
  }, [_c('v-row', {
    staticClass: "justify-center"
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "text-h5 black--text font-weight-bold"
  }, [_vm._v(_vm._s(_vm.tabContext[0].title))])]), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "text-subtitle-1 text-center"
  }, [_vm._v(_vm._s(_vm.fieldData(_vm.tabContext[0], 'sub-title').value))])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-alert', {
    staticStyle: {
      "white-space": "pre-wrap"
    },
    attrs: {
      "type": "success",
      "outlined": "",
      "text": ""
    }
  }, [_vm._v(_vm._s(_vm.fieldData(_vm.tabContext[0], 'desc').value))])], 1)], 1), _c('v-row', {
    staticClass: "justify-center"
  }, [_vm._l(_vm.fieldData(_vm.tabContext[0], 'service-list').children, function (item, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "6",
        "lg": "4",
        "md": "4"
      }
    }, [_c('v-card', {
      attrs: {
        "flat": ""
      }
    }, [_c('v-card-text', {
      staticClass: "text-center justify-center"
    }, [_c('v-img', {
      attrs: {
        "src": item.children[0].url,
        "max-height": "45px",
        "contain": ""
      }
    }), _c('p', {
      staticClass: "text-subtitle-1"
    }, [_vm._v(_vm._s(item.children[0].title) + " "), _c('br'), _vm._v(" " + _vm._s(item.children[0].value))])], 1)], 1)], 1);
  }), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('pre', {
    domProps: {
      "innerHTML": _vm._s(_vm.fieldData(_vm.tabContext[0], 'desc2').value)
    }
  }), _c('v-divider', {
    staticClass: "my-4"
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-list', [_c('v-subheader', [_vm._v(_vm._s(_vm.fieldData(_vm.tabContext[0], 'ent-title').value))]), _vm._l(_vm.fieldData(_vm.tabContext[0], 'ent-list').children, function (item, index) {
    return _c('v-list-item', {
      key: 'tab_1_list_' + index,
      attrs: {
        "three-line": ""
      }
    }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item.children[0].title))]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(item.children[0].value) + " ")])], 1), _c('v-list-item-icon', [_c('v-icon', [_vm._v("check")])], 1)], 1);
  })], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider', {
    staticClass: "my-4"
  })], 1), _vm._l(_vm.fieldData(_vm.tabContext[0], 'mode-list').children, function (item, index) {
    return _c('v-col', {
      key: 'tab_1_card_' + index,
      attrs: {
        "cols": "12",
        "lg": "4",
        "md": "4"
      }
    }, [_c('v-card', {
      attrs: {
        "flat": ""
      }
    }, [_c('v-row', [_c('v-col', {
      attrs: {
        "cols": "3"
      }
    }, [_c('v-img', {
      attrs: {
        "src": item.children[0].url,
        "width": "100px",
        "height": "100px",
        "contain": ""
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "9"
      }
    }, [_c('v-card-title', [_vm._v(" " + _vm._s(item.children[0].title) + " ")]), _c('v-card-subtitle', [_vm._v(" " + _vm._s(item.children[0].value) + " ")])], 1)], 1)], 1)], 1);
  })], 2)], 1)], 1)], 1), _c('v-tab-item', {
    attrs: {
      "transition": "fade-transition"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', {
    staticClass: "my-8"
  }, [_c('v-row', {
    staticClass: "justify-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('v-tabs', {
    staticClass: "bordered-tabs",
    attrs: {
      "centered": "",
      "plain": "",
      "show-arrows": "",
      "color": "black"
    }
  }, [_vm._l(_vm.tabContext[1].children[0].children, function (item, index) {
    return _c('v-tab', {
      key: 'tab_2_tab_' + index
    }, [_vm._v(_vm._s(item.title))]);
  }), _vm._l(_vm.tabContext[1].children[0].children, function (item, index) {
    return _c('v-tab-item', {
      key: 'tab_2_item_' + index,
      attrs: {
        "transition": "fade-transition"
      }
    }, [_c('v-card', {
      attrs: {
        "flat": ""
      }
    }, [_c('v-card-text', _vm._l(item.children, function (child, ci) {
      return _c('div', {
        key: 'fade_' + ci
      }, [child.type === 'text' ? [_c('p', {
        staticClass: "text-h6 black--text font-weight-bold"
      }, [_vm._v(_vm._s(child.title))]), _c('p', {
        staticClass: "text-body-1"
      }, [_vm._v(_vm._s(child.value))])] : _vm._e(), child.uname === 'banner' ? _c('v-img', {
        attrs: {
          "src": child.url,
          "contain": ""
        }
      }) : _vm._e(), child.uname === 'two-image' ? _c('v-row', [_c('v-col', {
        attrs: {
          "cols": "6"
        }
      }, [_c('v-img', {
        staticClass: "pa-4",
        attrs: {
          "src": child.children[0].children[0].url,
          "contain": ""
        }
      })], 1), _c('v-col', {
        attrs: {
          "cols": "6"
        }
      }, [_c('v-img', {
        staticClass: "pa-4",
        attrs: {
          "src": child.children[1].children[0].url,
          "contain": ""
        }
      })], 1)], 1) : _vm._e()], 2);
    }), 0)], 1)], 1);
  })], 2)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('contact-dialog', {
    attrs: {
      "showDialog": _vm.contactDialog,
      "type": "DEVICE_LEASING"
    },
    on: {
      "dialogCloseEvent": function dialogCloseEvent($event) {
        _vm.contactDialog = false;
      }
    }
  })], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }
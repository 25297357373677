<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-img :src="fieldData(pageData, 'banner').url" height="100%" width="100%"></v-img>
      </v-col>
      <v-col cols="12" class="d-flex justify-center mt-4">
        <span class="text-h3 font-weight-bold" style="white-space: pre-wrap">成功案例</span>
      </v-col>
      <v-col cols="12" class="d-flex justify-center">
        <p class="text-h5 text-center" style="white-space: pre-wrap">{{ fieldData(pageData, 'desc').value }}</p>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="text-center mb-12">
        <v-btn
          x-large
          depressed
          color="primary"
          class="rounded-xl large-btn"
          v-log="'SUCCESS_CASES'"
          @click="contactDialog = true"
        >
          与我们取得联系
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" lg="12">
        <v-tabs
          centered grow show-arrows color="black" class="bordered-tabs"
        >
          <v-tab v-for="(item, index) in fieldData(pageData, 'tab').children" :key="index">
            {{ item.title }}
          </v-tab>

          <!--零售-->
          <v-tab-item transition="fade-transition" v-for="(item, index) in fieldData(pageData, 'tab').children" :key="index">
            <v-card flat>
              <div v-for="(child, ci) in item.children" :key="'child_'+ ci">
                <template v-if="child.type === 'text'">
                  <v-row class="mt-8 mb-4" v-if="child.uname==='title'">
                    <v-col cols="12" class="text-h4 font-weight-bold text-center">
                      <span>{{ child.value }}</span>
                    </v-col>
                  </v-row>
                  <v-row class="mt-4 mb-4 grey-block" v-else-if="child.uname.endsWith('-grey')">
                    <v-col cols="12" class="text-h6">
                      <span>{{ child.title }}</span>
                    </v-col>
                    <v-col cols="12" class="text-body-1">
                      <p>
                        {{ child.value }}
                      </p>
                    </v-col>
                  </v-row>
                  <v-card-text class="mt-4 mb-4" v-else>
                    <v-row>
                      <v-col cols="12" class="text-h6 mt-4 mb-4">
                        <span>{{ child.title }}</span>
                      </v-col>
                      <v-col cols="12" class="text-body-1 grey--text">
                        <p>
                          {{ child.value }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </template>
                <v-card-text class="mt-4 mb-4" v-if="child.type === 'rich'">
                  <v-row>
                    <v-col cols="12" class="text-h6 mt-4 mb-4">
                      <span>{{ child.title }}</span>
                    </v-col>
                    <v-col cols="12" class="page-content">
                      <div v-html="child.value">
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-row v-if="child.type === 'image'">
                  <v-col cols="12" md="2" lg="2" v-if="child.uname === 'logo'">
                    <v-img :src="child.url" class="white--text"></v-img>
                  </v-col>
                  <v-col cols="12" v-else>
                    <v-img :src="child.url" class="white--text"></v-img>
                  </v-col>
                </v-row>
                <v-row v-if="child.type === 'video'">
                  <v-col cols="12">
                    <vue-plyr>
                      <video
                        controls
                        playsinline
                      >
                        <source
                          :src="child.url"
                          type="video/mp4"
                        />
                      </video>
                    </vue-plyr>
                  </v-col>
                </v-row>
                <v-row class="mt-4 grey-block" align="center" no-gutters v-if="child.type === 'list'">
                  <v-col cols="12" md="12" lg="12" class="d-flex justify-space-between align-center">
                    <span class="page-h5" style="width: 16%;">{{ child.children[0].children[0].title }}</span>
                    <p class="page-content" style="width: 31%;">
                      {{ child.children[0].children[0].value }}
                    </p>
                    <span class="page-h5" style="width: 6%;">&gt;</span>
                    <span class="page-h5" style="width: 16%;">{{ child.children[1].children[0].title }}</span>
                    <p class="page-content" style="width: 31%;">
                      {{ child.children[1].children[0].value }}
                    </p>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
    <contact-dialog :showDialog="contactDialog" type="SUCCESS_CASES" @dialogCloseEvent="contactDialog = false"></contact-dialog>
  </v-container>
</template>

<style lang="scss" scoped>
.container p {
  white-space: pre-line;
}

.page-content {
  font-weight: 300;
  font-size: 1rem;
  letter-spacing: 0.0125em;
  line-height: 2;

  p {
    color: #9e9e9e !important;
    caret-color: #9e9e9e !important;
  }
}

.page-btn {
  font-weight: 400;
  font-size: 24px;
}

.page-list {
  li {
    list-style: none;
  }
}

.activity-title {
  margin-left: auto;
  margin-right: auto;
}
.large-btn {
  width: 300px;
  font-size: 24px;
}
.tab-title {
  font-weight: 500;
  font-size: 24px;
  padding: 10px 40px;
  border-bottom: 1px solid #d9d9d9;
}

.tab-mobile-title {
  padding: 10px 10px;
  border-bottom: 1px solid #d9d9d9;
}

.grey-block {
  background-color: #f5f5f7;
  color: #0573d9;
  border-radius: 24px;
  padding: 30px 40px;
}

@media screen and (max-width:700px){
  .text-pretty {
    word-break: keep-all;
    word-wrap: break-word;
  }
}
</style>

<script>
import { SET_ERROR } from "@/store/mutations.type";
import {
  FETCH_CMS_CONTENT,
} from "@/store/actions.type";
import store from "@/store";

export default {
  data() {
    return {
      contactDialog: false,
      pageData: {},
    }
  },
  methods: {
    initPageData() {
      store.dispatch(FETCH_CMS_CONTENT, {name: 'success-cases', version: this.$route.query.version || ''})
        .then((data) => {
          this.pageData = data.data.result.tree;
        })
        .catch((err) => {
          store.commit(SET_ERROR, {msg: err.response.data.message});
        });
    }
  },
  created() {
    this.initPageData();
  },
  computed: {
    fieldData() {
      return function(data, name) {
        for (const field of data.children) {
          if (field.uname === name) {
            return field;
          }
        }
        return null;
      };
    },
  },
}
</script>

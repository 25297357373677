<template>
  <el-dialog
    title=""
    :visible.sync="dialogVisible"
    :fullscreen="$vuetify.breakpoint.mobile"
    width="616px"
    :before-close="handleClose"
    :append-to-body="true"
    :lock-scroll="false"
    class="aiContainer"
    v-dialogDrag
    >
      <iframe
        id="frame"
        class="aiFrame"
        :src="aiUrl">
      </iframe>
  </el-dialog>
</template>

<script>
export default {
  props: ['dialogVisible'],
  data () {
    return {
      aiUrl: process.env.VUE_APP_AI_URL,
    }
  },
  methods: {
    handleClose() {
      this.$emit('dialogVisibleEvent', false);
    }
  },
  mounted() {
    const that = this;
    window.addEventListener('message',function(event){
      if (event.data === 'contact') {
        //弹出留资对话框
        that.$emit('openContactEvent');
      }
    })
  },
  watch: {
    // showDialog(val) {
    //   this.dialogVisible = val;
    // }
  },
  // beforeCreate() {
  //   console.log("子组件————beforeCreate...");
  // },
  // created() {
  //   console.log("子组件————create...");
  // },
  // beforeMount() {
  //   console.log("子组件————beforeMount...");
  // },
  // mounted() {
  //   console.log("子组件————mounted...");
  // },
  // beforeUpdate() {
  //   console.log("子组件————beforeUpdate...");
  // },
  // updated() {
  //   console.log("子组件————updated...");
  // },
  // beforeDestroy() {
  //   console.log("子组件————beforeDestroy...");
  // },
  // destroyed() {
  //   console.log("子组件————destroyed...");
  // },
}
</script>
<style lang="scss" scoped>
.aiContainer {
  bottom: 0 !important;
  right: 0 !important;
  position: absolute !important;

  ::v-deep .el-dialog {
    height: 600px;
    overflow: hidden;
    background: linear-gradient(135deg, #e9ffff, #f8f4ff, #f6f6f6);

    .el-dialog__body {
      padding: 10px;
      overflow: hidden;
      display: flex;
      height: 96%;
    }
  }
}
.aiFrame {
  width: 100%;
  height: auto;
  border: 0px;
  margin:0;
  flex-grow: 1;
}

.closePanel {
  margin: 0;
  padding: 0 !important;
  height: 36px;
  overflow: hidden;
  overflow-y: hidden !important;
}

.closeBtn {
  float: right;
  padding: 0;
  color: #000 !important;
}

.aiCard {
  height: 700px;
}
@media screen and (max-width:700px){
  .aiCard {
    height: 100%;
  }

  ::v-deep .el-dialog {
    height: 100% !important;
  }
}
</style>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-img', {
    attrs: {
      "src": _vm.fieldData(_vm.pageData, 'banner').url,
      "height": "100%",
      "width": "100%"
    }
  })], 1), _c('v-col', {
    staticClass: "d-flex justify-center mt-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "text-h3 font-weight-bold",
    staticStyle: {
      "white-space": "pre-wrap"
    }
  }, [_vm._v("成功案例")])]), _c('v-col', {
    staticClass: "d-flex justify-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "text-h5 text-center",
    staticStyle: {
      "white-space": "pre-wrap"
    }
  }, [_vm._v(_vm._s(_vm.fieldData(_vm.pageData, 'desc').value))])])], 1), _c('v-row', [_c('v-col', {
    staticClass: "text-center mb-12",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    directives: [{
      name: "log",
      rawName: "v-log",
      value: 'SUCCESS_CASES',
      expression: "'SUCCESS_CASES'"
    }],
    staticClass: "rounded-xl large-btn",
    attrs: {
      "x-large": "",
      "depressed": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.contactDialog = true;
      }
    }
  }, [_vm._v(" 与我们取得联系 ")])], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('v-tabs', {
    staticClass: "bordered-tabs",
    attrs: {
      "centered": "",
      "grow": "",
      "show-arrows": "",
      "color": "black"
    }
  }, [_vm._l(_vm.fieldData(_vm.pageData, 'tab').children, function (item, index) {
    return _c('v-tab', {
      key: index
    }, [_vm._v(" " + _vm._s(item.title) + " ")]);
  }), _vm._l(_vm.fieldData(_vm.pageData, 'tab').children, function (item, index) {
    return _c('v-tab-item', {
      key: index,
      attrs: {
        "transition": "fade-transition"
      }
    }, [_c('v-card', {
      attrs: {
        "flat": ""
      }
    }, _vm._l(item.children, function (child, ci) {
      return _c('div', {
        key: 'child_' + ci
      }, [child.type === 'text' ? [child.uname === 'title' ? _c('v-row', {
        staticClass: "mt-8 mb-4"
      }, [_c('v-col', {
        staticClass: "text-h4 font-weight-bold text-center",
        attrs: {
          "cols": "12"
        }
      }, [_c('span', [_vm._v(_vm._s(child.value))])])], 1) : child.uname.endsWith('-grey') ? _c('v-row', {
        staticClass: "mt-4 mb-4 grey-block"
      }, [_c('v-col', {
        staticClass: "text-h6",
        attrs: {
          "cols": "12"
        }
      }, [_c('span', [_vm._v(_vm._s(child.title))])]), _c('v-col', {
        staticClass: "text-body-1",
        attrs: {
          "cols": "12"
        }
      }, [_c('p', [_vm._v(" " + _vm._s(child.value) + " ")])])], 1) : _c('v-card-text', {
        staticClass: "mt-4 mb-4"
      }, [_c('v-row', [_c('v-col', {
        staticClass: "text-h6 mt-4 mb-4",
        attrs: {
          "cols": "12"
        }
      }, [_c('span', [_vm._v(_vm._s(child.title))])]), _c('v-col', {
        staticClass: "text-body-1 grey--text",
        attrs: {
          "cols": "12"
        }
      }, [_c('p', [_vm._v(" " + _vm._s(child.value) + " ")])])], 1)], 1)] : _vm._e(), child.type === 'rich' ? _c('v-card-text', {
        staticClass: "mt-4 mb-4"
      }, [_c('v-row', [_c('v-col', {
        staticClass: "text-h6 mt-4 mb-4",
        attrs: {
          "cols": "12"
        }
      }, [_c('span', [_vm._v(_vm._s(child.title))])]), _c('v-col', {
        staticClass: "page-content",
        attrs: {
          "cols": "12"
        }
      }, [_c('div', {
        domProps: {
          "innerHTML": _vm._s(child.value)
        }
      })])], 1)], 1) : _vm._e(), child.type === 'image' ? _c('v-row', [child.uname === 'logo' ? _c('v-col', {
        attrs: {
          "cols": "12",
          "md": "2",
          "lg": "2"
        }
      }, [_c('v-img', {
        staticClass: "white--text",
        attrs: {
          "src": child.url
        }
      })], 1) : _c('v-col', {
        attrs: {
          "cols": "12"
        }
      }, [_c('v-img', {
        staticClass: "white--text",
        attrs: {
          "src": child.url
        }
      })], 1)], 1) : _vm._e(), child.type === 'video' ? _c('v-row', [_c('v-col', {
        attrs: {
          "cols": "12"
        }
      }, [_c('vue-plyr', [_c('video', {
        attrs: {
          "controls": "",
          "playsinline": ""
        }
      }, [_c('source', {
        attrs: {
          "src": child.url,
          "type": "video/mp4"
        }
      })])])], 1)], 1) : _vm._e(), child.type === 'list' ? _c('v-row', {
        staticClass: "mt-4 grey-block",
        attrs: {
          "align": "center",
          "no-gutters": ""
        }
      }, [_c('v-col', {
        staticClass: "d-flex justify-space-between align-center",
        attrs: {
          "cols": "12",
          "md": "12",
          "lg": "12"
        }
      }, [_c('span', {
        staticClass: "page-h5",
        staticStyle: {
          "width": "16%"
        }
      }, [_vm._v(_vm._s(child.children[0].children[0].title))]), _c('p', {
        staticClass: "page-content",
        staticStyle: {
          "width": "31%"
        }
      }, [_vm._v(" " + _vm._s(child.children[0].children[0].value) + " ")]), _c('span', {
        staticClass: "page-h5",
        staticStyle: {
          "width": "6%"
        }
      }, [_vm._v(">")]), _c('span', {
        staticClass: "page-h5",
        staticStyle: {
          "width": "16%"
        }
      }, [_vm._v(_vm._s(child.children[1].children[0].title))]), _c('p', {
        staticClass: "page-content",
        staticStyle: {
          "width": "31%"
        }
      }, [_vm._v(" " + _vm._s(child.children[1].children[0].value) + " ")])])], 1) : _vm._e()], 2);
    }), 0)], 1);
  })], 2)], 1)], 1), _c('contact-dialog', {
    attrs: {
      "showDialog": _vm.contactDialog,
      "type": "SUCCESS_CASES"
    },
    on: {
      "dialogCloseEvent": function dialogCloseEvent($event) {
        _vm.contactDialog = false;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
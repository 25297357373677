<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card class="mx-auto my-12" max-width="100%" flat>
          <v-img
            height="100%"
            :src="page_info.config.bannerList[0].url"
          ></v-img>
        </v-card>
      </v-col>
      <v-col cols="12" class="text-center">
        <p class="text-h4 text-md-h4">{{page_info.config.fieldOne}}</p>
        <pre class="text-h4 text-md-h3 font-weight-bold">{{page_info.config.fieldTwo}}</pre>
        <p class="text-md-h5 my-4 grey--text">{{page_info.config.fieldThree}}</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="4" md="4" v-for="(item, index) in category" :key="'category_'+ index">
        <v-card flat :to="{ path: '/product/'+ encodeURIComponent(item.categoryName.toLowerCase()), query: { brandId: item.brandId, firstCategoryId: item.id } }">
          <v-card-text class="text-center justify-center">
            <p class="text-h4 black--text">{{item.categoryName}}</p>
            <p class="subtitle-1">{{item.explainInfo}}</p>
            <v-img :src="item.imgUrl" max-height="150px" contain></v-img>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import store from "@/store";
import {
  mapGetters
} from "vuex";
import {
  FETCH_CATEGORY_LIST,
  FETCH_PROD_BRAND
} from "@/store/actions.type";

export default {
  data() {
    return {
      page_info: {}
    }
  },
  beforeRouteEnter(to, from, next) {
    Promise.all([
      store.dispatch(FETCH_CATEGORY_LIST),
    ]).then(() => {
      next();
    });
  },
  computed: {
    ...mapGetters(['category']),
  },
  created() {
    this.initPageData();
  },
  methods: {
    initPageData() {
      store.dispatch(FETCH_PROD_BRAND, 'APPLE')
        .then((data) => {
          this.page_info = Object.assign({}, data.result)
        })
    }
  }
}
</script>


import { render, staticRenderFns } from "./index.vue?vue&type=template&id=89ab2498&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=89ab2498&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.0_cache-loader@4.1.0_webpack@4.47.0__css-loader@3.6._lt4lsz5e2aihibmjrnrcojslke/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89ab2498",
  null
  
)

export default component.exports
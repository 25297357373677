import { render, staticRenderFns } from "./RepairDetail.vue?vue&type=template&id=e665b2ea&scoped=true"
import script from "./RepairDetail.vue?vue&type=script&lang=js"
export * from "./RepairDetail.vue?vue&type=script&lang=js"
import style0 from "./RepairDetail.vue?vue&type=style&index=0&id=e665b2ea&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.0_cache-loader@4.1.0_webpack@4.47.0__css-loader@3.6._lt4lsz5e2aihibmjrnrcojslke/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e665b2ea",
  null
  
)

export default component.exports
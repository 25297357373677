var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('widgets-carousel', {
    attrs: {
      "list": _vm.banners
    }
  })], 1)], 1), _vm.fieldData(_vm.pageData, 'images1') != null ? _c('v-row', _vm._l(_vm.fieldData(_vm.pageData, 'images1').children, function (image, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "12",
        "md": "6",
        "lg": "6"
      }
    }, [_c('v-card', {
      attrs: {
        "flat": ""
      }
    }, [_c('a', {
      attrs: {
        "href": image.children[0].jumpUrl
      }
    }, [_c('v-img', {
      staticClass: "white--text align-end",
      attrs: {
        "src": image.children[0].url
      }
    })], 1)])], 1);
  }), 1) : _vm._e(), _c('v-row', [_c('v-col', {
    staticClass: "mt-16",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "text-center text-h4 text-md-h3 font-weight-bold"
  }, [_vm._v(_vm._s(_vm.fieldData(_vm.pageData, 'title').value))])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "text-center text-md-h6 font-weight-medium"
  }, [_vm._v(_vm._s(_vm.fieldData(_vm.pageData, 'sub-title').value))])])], 1), _c('v-row', _vm._l(_vm.fieldData(_vm.pageData, 'images2').children, function (image, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "12",
        "md": "6",
        "lg": "6"
      }
    }, [_c('v-card', {
      attrs: {
        "flat": ""
      }
    }, [_c('a', {
      staticStyle: {
        "text-decoration": "none"
      },
      attrs: {
        "href": image.children[0].jumpUrl
      }
    }, [_c('v-img', {
      attrs: {
        "src": image.children[0].url
      }
    }, [_c('v-card-title', {
      staticClass: "text-h4 font-weight-bold justify-center mt-2"
    }, [_vm._v(_vm._s(image.children[0].title))]), _c('p', {
      staticClass: "text-md-h6 font-weight-medium text-center justify-center mt-0"
    }, [_vm._v(_vm._s(image.children[0].value))])], 1)], 1)])], 1);
  }), 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "text-center text-h4 font-weight-bold mt-16"
  }, [_vm._v("告诉我们您的业务需求")]), _c('p', {
    staticClass: "text-center text-h6 font-weight-medium"
  }, [_vm._v("info@doublerise.com")])])], 1), _c('v-row', {
    staticClass: "mb-6"
  }, [_c('v-col', {
    attrs: {
      "cols": "0",
      "md": "0",
      "lg": "2"
    }
  }), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "8"
    }
  }, [_c('contact-demand', {
    attrs: {
      "type": "BOTTOM_OF_HOMEPAGE"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "0",
      "md": "0",
      "lg": "2"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
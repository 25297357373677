var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "mx-auto my-12",
    attrs: {
      "max-width": "100%",
      "flat": ""
    }
  }, [_c('v-img', {
    attrs: {
      "height": "100%",
      "src": _vm.page_info.config.bannerList[0].url
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "text-h4 text-md-h4"
  }, [_vm._v(_vm._s(_vm.page_info.config.fieldOne))]), _c('pre', {
    staticClass: "text-h4 text-md-h3 font-weight-bold"
  }, [_vm._v(_vm._s(_vm.page_info.config.fieldTwo))]), _c('p', {
    staticClass: "text-md-h5 my-4 grey--text"
  }, [_vm._v(_vm._s(_vm.page_info.config.fieldThree))])])], 1), _c('v-row', _vm._l(_vm.category, function (item, index) {
    return _c('v-col', {
      key: 'category_' + index,
      attrs: {
        "cols": "12",
        "lg": "4",
        "md": "4"
      }
    }, [_c('v-card', {
      attrs: {
        "flat": "",
        "to": {
          path: '/product/' + encodeURIComponent(item.categoryName.toLowerCase()),
          query: {
            brandId: item.brandId,
            firstCategoryId: item.id
          }
        }
      }
    }, [_c('v-card-text', {
      staticClass: "text-center justify-center"
    }, [_c('p', {
      staticClass: "text-h4 black--text"
    }, [_vm._v(_vm._s(item.categoryName))]), _c('p', {
      staticClass: "subtitle-1"
    }, [_vm._v(_vm._s(item.explainInfo))]), _c('v-img', {
      attrs: {
        "src": item.imgUrl,
        "max-height": "150px",
        "contain": ""
      }
    })], 1)], 1)], 1);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
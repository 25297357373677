<template>
  <v-container v-if="pageData">
    <v-row>
      <v-col cols="12">
        <v-img :src="fieldData(pageData, 'banner').url" width="100%" contain></v-img>
      </v-col>
      <v-col cols="12" class="d-flex justify-center mt-4">
        <span class="text-h3 font-weight-bold" style="white-space: pre-wrap">{{ fieldData(pageData, 'title').value }}</span>
      </v-col>
      <v-col cols="12" class="d-flex justify-center">
        <p class="text-h5 text-center" style="white-space: pre-wrap">{{ fieldData(pageData, 'sub-title').value }}</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-center">
        <v-btn
          x-large
          rounded
          depressed
          color="primary"
          class="rounded-xl large-btn"
          v-log="'DEVICE_LEASING'"
          @click="contactDialog = true"
        >
          与我们取得联系
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-12">
      <v-col cols="12" md="12" lg="12">
        <v-tabs centered show-arrows color="black" class="bordered-tabs">
          <v-tab>MDaaS 租赁服务</v-tab>
          <v-tab>客户案例介绍</v-tab>

          <!-- MDaaS 租赁服务-->
          <v-tab-item transition="fade-transition">
            <v-card flat>
              <v-card-text class="my-8">
                <v-row class="justify-center">
                  <v-col cols="12" class="text-center">
                    <span class="text-h5 black--text font-weight-bold">{{ tabContext[0].title }}</span>
                  </v-col>
                  <v-col cols="12" class="text-center">
                    <p class="text-subtitle-1 text-center">{{ fieldData(tabContext[0], 'sub-title').value }}</p>
                  </v-col>
                  <v-col cols="12">
                    <v-alert type="success" outlined text style="white-space: pre-wrap">{{ fieldData(tabContext[0], 'desc').value }}</v-alert>
                  </v-col>
                </v-row>
                <v-row class="justify-center">
                  <v-col cols="6" lg="4" md="4" v-for="(item, index) in fieldData(tabContext[0], 'service-list').children" :key="index">
                    <v-card flat>
                      <v-card-text class="text-center justify-center">
                        <v-img :src="item.children[0].url" max-height="45px" contain></v-img>
                        <p class="text-subtitle-1">{{ item.children[0].title }} <br> {{ item.children[0].value }}</p>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" class="text-center">
                    <pre v-html="fieldData(tabContext[0], 'desc2').value"></pre>
                    <v-divider class="my-4"></v-divider>
                  </v-col>
                  <v-col cols="6">
                    <v-list>
                      <v-subheader>{{ fieldData(tabContext[0], 'ent-title').value }}</v-subheader>
                      <v-list-item three-line v-for="(item, index) in fieldData(tabContext[0], 'ent-list').children" :key="'tab_1_list_'+ index">
                        <v-list-item-content>
                          <v-list-item-title>{{ item.children[0].title }}</v-list-item-title>
                          <v-list-item-subtitle>
                            {{ item.children[0].value }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-icon>
                          <v-icon>check</v-icon>
                        </v-list-item-icon>
                      </v-list-item>
                    </v-list>
                  </v-col>
                  <v-col cols="12"><v-divider class="my-4"></v-divider></v-col>
                  <v-col cols="12" lg="4" md="4" v-for="(item, index) in fieldData(tabContext[0], 'mode-list').children" :key="'tab_1_card_'+index">
                    <v-card flat>
                      <v-row>
                        <v-col cols="3">
                          <v-img :src="item.children[0].url" width="100px" height="100px" contain></v-img>
                        </v-col>
                        <v-col cols="9">
                          <v-card-title>
                            {{item.children[0].title}}
                          </v-card-title>
                          <v-card-subtitle>
                            {{item.children[0].value}}
                          </v-card-subtitle>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!--客户案例介绍-->
          <v-tab-item transition="fade-transition">
            <v-card flat>
              <v-card-text class="my-8">
                <v-row class="justify-center">
                  <v-col cols="12" md="12" lg="12">
                    <v-tabs centered plain show-arrows color="black" class="bordered-tabs">
                      <v-tab v-for="(item, index) in tabContext[1].children[0].children" :key="'tab_2_tab_'+ index">{{item.title}}</v-tab>

                      <v-tab-item transition="fade-transition" v-for="(item, index) in tabContext[1].children[0].children" :key="'tab_2_item_'+ index">
                        <v-card flat>
                          <v-card-text>
                            <div v-for="(child, ci) in item.children" :key="'fade_'+ ci">
                              <template v-if="child.type === 'text'">
                                <p class="text-h6 black--text font-weight-bold">{{ child.title }}</p>
                                <p class="text-body-1">{{ child.value }}</p>
                              </template>
                              <v-img v-if="child.uname === 'banner'" :src="child.url" contain></v-img>
                              <v-row v-if="child.uname === 'two-image'">
                                <v-col cols="6"><v-img :src="child.children[0].children[0].url" contain class="pa-4"></v-img></v-col>
                                <v-col cols="6"><v-img :src="child.children[1].children[0].url" contain class="pa-4"></v-img></v-col>
                              </v-row>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                    </v-tabs>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
    <contact-dialog :showDialog="contactDialog" type="DEVICE_LEASING" @dialogCloseEvent="contactDialog = false"></contact-dialog>
  </v-container>
</template>

<style lang="scss" scoped>
.container p {
  white-space: pre-line;
}
</style>

<script>
import { SET_ERROR } from "@/store/mutations.type";
import {
  FETCH_CMS_CONTENT,
} from "@/store/actions.type";
import store from "@/store";

export default {
  data() {
    return {
      contactDialog: false,
      pageData: {},
      tabContext: [],
    }
  },
  methods: {
    initPageData() {
      store.dispatch(FETCH_CMS_CONTENT, {name: 'equipment-lease', version: this.$route.query.version || ''})
        .then((data) => {
          this.pageData = data.data.result.tree;
          this.tabContext = this.fieldData(data.data.result.tree, 'tab').children
        })
        .catch((err) => {
          store.commit(SET_ERROR, {msg: err.response.data.message});
        });
    }
  },
  created() {
    this.initPageData();
  },
  computed: {
    fieldData() {
      return function(data, name) {
        for (const field of data.children) {
          if (field.uname === name) {
            return field;
          }
        }
        return null;
      };
    },
  },
}
</script>
<style lang="scss" scoped>
.container p {
  white-space: pre-line;
}
.bordered-tabs >>> .v-slide-group__content {
  border-bottom: 1px solid #0000001e;
}
.v-tab {
  text-transform: none !important;
}
</style>

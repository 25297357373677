<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-img :src="fieldData(pageData, 'banner').url" height="100%" width="100%"></v-img>
      </v-col>
      <v-col cols="12" class="d-flex justify-center mt-4">
        <span class="text-h3 font-weight-bold" style="white-space: pre-wrap">售后和管理</span>
      </v-col>
      <v-col cols="12" class="d-flex justify-center">
        <p class="text-h5 text-center" style="white-space: pre-wrap">{{ fieldData(pageData, 'sub-title').value }}</p>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="text-center mb-12">
        <v-menu v-if="!isAuthenticated" transition="scroll-y-transition" min-width="100" :nudge-width="100" top offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              x-large
              depressed
              color="primary"
              class="rounded-xl large-btn"
              v-bind="attrs"
              v-on="on"
            >
              马上申报服务
            </v-btn>
          </template>
          <v-card>
            <v-card-text>
              请登录您的专属账户
            </v-card-text>
            <v-card-actions class="text-center">
              <v-btn
                :to="{ path: '/account' }"
                depressed
                plat
                >注册</v-btn>
              <v-btn
                :to="{ path: '/login' }"
                depressed
                plat
                >登录</v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
        <v-btn
          v-if="isAuthenticated"
          x-large
          depressed
          color="primary"
          class="rounded-xl large-btn"
          v-log="'AFTER_SALES_WITH_SERVICE'"
          @click="showServiceDialog = true"
        >
          马上申报服务
        </v-btn>
        <contact-service :showDialog="showServiceDialog" @dialogCloseEvent="showServiceDialog = false"></contact-service>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" lg="12">
        <v-tabs
          centered grow show-arrows color="black" class="bordered-tabs"
        >
          <v-tab>
            运营维护
          </v-tab>
          <v-tab>
            设备部署
          </v-tab>
          <v-tab>
            检测维修
          </v-tab>

          {{ (tab1 = fieldData(pageData, 'tab').children[0], void 0) }}
          <!--运营维护-->
          <v-tab-item transition="fade-transition">
            <v-card flat>
              <v-card-text class="mt-4 mb-4">
                <v-row>
                  <v-col cols="12" class="d-flex justify-center mt-4">
                    <span class="text-h5 black--text font-weight-bold" style="white-space: pre-wrap">运营维护</span>
                  </v-col>
                  <v-col cols="12" class="text-md-h5 my-4 grey--text text-center">
                    <p>
                      {{ fieldData(tab1, 'title').value }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="text-h6 mt-4 mb-4">
                    <span>{{ fieldData(tab1, 'service').title }}</span>
                  </v-col>
                  <v-col cols="12" class="text-body-1 grey--text">
                    <p>
                      {{ fieldData(tab1, 'service').value }}
                    </p>
                  </v-col>
                </v-row>

                <v-card flat>
                  <v-card flat class="d-flex justify-space-between align-center">
                    <img class="operImg" v-for="(item,index) in fieldData(tab1, 'service-list').children" :key="index" :src="item.children[0].url" />
                  </v-card>
                  <v-card flat>
                    <!-- <img class="operLine" src="/static/after-sales/oper_line.png" /> -->
                    <v-stepper flat :vertical="true">
                      <v-stepper-header class="elevation-0">
                        <v-stepper-step step="" color="#b2b2b2" class="text-center">
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="" color="#b2b2b2">
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="" color="#b2b2b2">
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="" color="#b2b2b2">
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="" color="#b2b2b2">
                        </v-stepper-step>
                      </v-stepper-header>
                    </v-stepper>
                  </v-card>
                  <v-card flat class="d-flex justify-space-between">
                    <v-card flat v-for="(item,index) in fieldData(tab1, 'service-list').children" :key="index">
                      <span class="text-h6">{{ item.children[0].title }}</span>
                      <p class="text-body-1 grey--text">
                        {{ item.children[0].value }}
                      </p>
                    </v-card>
                  </v-card>
                </v-card>
              </v-card-text>
            </v-card>
          </v-tab-item>

          {{ (tab2 = fieldData(pageData, 'tab').children[1], void 0) }}
          <!--设备部署-->
          <v-tab-item transition="fade-transition">
            <v-card flat>
              <v-card-text class="mt-4 mb-4">
                <v-row>
                  <v-col cols="12" class="d-flex justify-center mt-4">
                    <span class="text-h5 black--text font-weight-bold" style="white-space: pre-wrap">设备部署</span>
                  </v-col>
                  <v-col cols="12" class="text-h6 my-4 grey--text text-center">
                    <p>
                      {{ fieldData(tab1, 'title').value }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4" class="text-h6 text-center" v-for="(item, index) in fieldData(tab2, 'image-list').children" :key="index">
                    <span><v-img class="image-deploy" style="margin: auto;" :src="item.children[0].url" /></span>
                    <span style="line-height: 40px;">{{ item.children[0].title }}</span>
                  </v-col>
                </v-row>
                <div class="page-content my-8" v-html="fieldData(tab2, 'desc').value">
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>

          {{ (tab3 = fieldData(pageData, 'tab').children[2], void 0) }}
          <!--检测维修-->
          <v-tab-item transition="fade-transition">
            <v-card flat>
              <v-card-text class="mt-4 mb-4">
                <v-row>
                  <v-col cols="12" class="d-flex justify-center mt-4">
                    <span class="text-h5 black--text font-weight-bold" style="white-space: pre-wrap">检测维修</span>
                  </v-col>
                  <v-col cols="12" class="text-md-h5 my-4 grey--text text-center">
                    <p>
                      <v-img :src="fieldData(tab3, 'banner').url" class="white--text"></v-img>
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="text-body-1 grey--text">
                    <p>
                      {{ fieldData(tab3, 'desc').value }}
                    </p>
                  </v-col>
                  <v-col cols="12" class="text-body-1 grey--text text-center">
                    <p>
                      {{ fieldData(tab3, 'list-title').value }}
                    </p>
                  </v-col>
                  <template v-for="(item, index) in fieldData(tab3, 'image-list').children">
                    <v-col v-if="index<=3" cols="3" class="text-body-1 grey--text text-center">
                      <img class="image-main" :src="item.children[0].url" />
                      <p>
                        {{ item.children[0].title }}
                      </p>
                    </v-col>
                    <v-col v-if="index>3" cols="4" class="text-body-1 grey--text text-center">
                      <img class="image-main" :src="item.children[0].url" />
                      <p>
                        {{ item.children[0].title }}
                      </p>
                    </v-col>
                  </template>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss" scoped>

.container p {
  white-space: pre-line;
}
.page-btn {
  font-weight: 400;
  font-size: 24px;
}

.page-list {
  li {
    list-style: none;
  }
}
.large-btn {
  width: 300px;
  font-size: 24px;
}
.tab-title {
  font-weight: 500;
  font-size: 24px;
  padding: 10px 40px;
  border-bottom: 1px solid #d9d9d9;
}

.tab-mobile-title {
  padding: 10px 10px;
  border-bottom: 1px solid #d9d9d9;
}

.grey-block {
  background-color: #f5f5f7;
  color: #0573d9;
  border-radius: 24px;
  padding: 30px 40px;
}

.v-stepper__step {
  &:nth-child(1) {
    padding: 24px 24px 16px 0;
  }
}

.operImgList {
  width: 910px;
  margin: 0 auto;
}

.operImg {
  height: 98px;
}

.operLine {
  width: 95%;
  height: 20px;
  margin: 20px 20px;
}

.image-deploy {
  width: 250px;
  height: 220px;
  margin: 20px 20px 0 20px;
  border: 1px solid rgba(0, 0, 0, .1);
}

.image-main {
  height: 80px;
}

.page-content {
  font-weight: 300;
  font-size: 1rem;
  letter-spacing: 0.0125em;
  line-height: 2;

  p {
    color: #9e9e9e !important;
    caret-color: #9e9e9e !important;
  }
}

@media screen and (max-width:700px){
  .operImg {
    height: 60px;
  }

  .image-main {
    height: 60px;
  }

  .text-pretty {
    word-break: keep-all;
    word-wrap: break-word;
  }
}
</style>

<script>
import {
  mapGetters
} from "vuex";
import { SET_ERROR } from "@/store/mutations.type";
import {
  FETCH_CMS_CONTENT,
} from "@/store/actions.type";
import store from "@/store";

export default {
  data() {
    return {
      showServiceDialog: false,
      pageData: {},
    }
  },
  methods: {
    initPageData() {
      store.dispatch(FETCH_CMS_CONTENT, {name: 'after-sales', version: this.$route.query.version || ''})
        .then((data) => {
          this.pageData = data.data.result.tree;
        })
        .catch((err) => {
          store.commit(SET_ERROR, {msg: err.response.data.message});
        });
    }
  },
  created() {
    this.initPageData();
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
    fieldData() {
      return function(data, name) {
        for (const field of data.children) {
          if (field.uname === name) {
            return field;
          }
        }
        return null;
      };
    },
  },
}
</script>

const ID_TOKEN_KEY = "id_token";
const USER_INFO_KEY = "userInfo";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const getUser = () => {
  const userInfo = window.localStorage.getItem(USER_INFO_KEY);
  if (userInfo === undefined || userInfo === null) {
    return null;
  }
  return JSON.parse(userInfo);
};

export const saveUser = user => {
  window.localStorage.setItem(USER_INFO_KEY, JSON.stringify(user));
};

export const destroyUser = () => {
  window.localStorage.removeItem(USER_INFO_KEY);
};

export default { getToken, saveToken, destroyToken, getUser, saveUser, destroyUser };
